import { Container } from '@mui/material'
import React from 'react'
import '../../Pages/courselist.css'
import CountUp from 'react-countup'

const NoLearners = () => {



    return (
        <Container maxWidth style={{ background: "#000" }} >
            <div className="no-learner-container">
                <p className="learner-heading">
                    Unrivalled Quality
                </p>
                <p className="learners-description">
                    Our top-notch content and faculty are handpicked to provide you the best learning experience possible.
                </p>
                <div className="div-learner-count-container">
                    <div className="learner-div">
                        <p className="count" id='inst'>
                            <CountUp end={Math.floor(Math.random() * 50)} />
                        </p>
                        <p className="count-desc">
                            Qualified Instructors
                        </p>
                    </div>
                    <div className="learner-div">
                        <p className="count">
                            <CountUp end={Math.floor(Math.random() * 35)} />
                        </p>
                        <p className="count-desc">
                            Expert-authored Courses
                        </p>
                    </div>
                    <div className="learner-div">
                        <p className="count">
                            <CountUp end={Math.floor(Math.random() * 20000)} />
                        </p>
                        <p className="count-desc">
                            Satisfied Learners
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default NoLearners