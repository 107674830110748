import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getAuth, inMemoryPersistence, setPersistence } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCk86NgWCldOYRdq8gr8RSXjk-ZDedsR6I",
    authDomain: "elearning-de474.firebaseapp.com",
    projectId: "elearning-de474",
    storageBucket: "elearning-de474.appspot.com",
    messagingSenderId: "498982770585",
    appId: "1:498982770585:web:9628e671411ff0176ae508",
    measurementId: "G-H9XYFWQ31D"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const AUTH = getAuth(app)
setPersistence(AUTH, inMemoryPersistence)
export const Analytics = getAnalytics(app);
export const db = getFirestore(app)