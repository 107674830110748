import React from 'react'
import './Termsandconditions.css'
import { Box, Container } from '@mui/material'

import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { IconButton } from 'ui-neumorphism'

const PrivacyAndPolicy = () => {
    return (
        <Container maxWidth >
            <div className="support">
                <Box display="inline-flex" mx='auto' className='support-social' justifyContent='space-between' alignItems='center'  >
                    <IconButton className='util' text={false} size='large' rounded >
                        <FacebookOutlined style={{
                            color: "#111"
                        }} />
                    </IconButton>
                    <IconButton className='util' text={false} size='large' rounded >
                        <a href="https://instagram.com/quizaroextendededge?igshid=NTc4MTIwNjQ2YQ==">
                            <InstagramOutlined style={{
                                color: "#111"
                            }} />
                        </a>
                    </IconButton>
                    <a href=" https://www.linkedin.com/company/quizaro-extendededge/">
                        <IconButton className='util' text={false} size='large' rounded >
                            <LinkedinOutlined style={{
                                color: "#111"
                            }} color='primary' />
                        </IconButton>
                    </a>
                    <IconButton className='util' text={false} size='large' rounded >
                        <a href="https://wa.me/qr/ZKNWI6ZI3WGTJ1">
                            <WhatsAppOutlined style={{
                                color: "#111"
                            }} color='primary' />
                        </a>
                    </IconButton>
                </Box>
                <div className='contact-links' >
                    <MailOutlined style={{
                        color: "#fff",
                        margin: "0 5px"
                    }} />
                    Support@quizaro.in
                </div>
                <div className='contact-links'>
                    <PhoneOutlined style={{
                        color: "#fff",
                        margin: "0 5px"

                    }} />
                    9380036855
                </div>
            </div>
            <div className='terms-and-condition'>
                <h3 className='main-heading'>
                    Privacy Policy
                </h3>
                <p>
                    Quizaro is owned and operated by Quizaro ExtentedEdge Pvt. Ltd. which expression shall mean and include its affiliates, successors and assigns). This Policy constitutes a legal agreement between You, as the user of the Website, and Quizaro, as the owner of the Website. This Policy does not apply to third-party websites that are connected via links to the Website. We encourage you to take the time to read this Privacy Policy as well as those of any other sites you visit, whether through a link on that site or otherwise.
                    By Using The Company Website, Or By Otherwise Giving Us Your Information, You Will Be Deemed To Have Read, Understood And Agreed To The Practices And Policies Outlined In This Privacy Policy And Agree To Be Bound By The Privacy Policy. You Hereby Consent To Our Collection, Use And Sharing, Disclosure Of Your Information As Described In This Privacy Policy. We Reserve The Right To Change, Modify, Add Or Delete Portions Of The Terms Of This Privacy Policy, At Our Sole Discretion, At Any Time.
                </p>
                <h4 className="second-heading">
                    AAPPLICABILITY
                </h4>
                <p>
                    This privacy policy (“Policy”) describes Our policies and procedures on the collection, use, storage and disclosure of any information including, business or personal information provided by You while using Our Website.
                </p>
                <h4 className="second-heading">
                    COLLECTION OF INFORMATION
                </h4>
                <p>
                    <b> Personally Identifiable Information </b> <br />

                    <p>
                        Quizaro will use the information provided by You only in accordance with the purposes described in the Policy. During Your use of Our Website, We may collect and process such information from You, including but not limited to the below mentioned:</p>
                    <p>
                        Information that You provide to Us by filling in forms on the Website. This includes contact information such as name, email address, mailing address, phone number, any financial information, if any, unique identifiers such as user name, account number and password.
                    </p>
                    <p>
                        Information that You provide when You write directly to Us (including by e-mail).
                    </p>
                    <p>
                        Information that You provide to Us over telephone. We may make and keep a record of the information You share with us;Information that You provide to Us by completing surveys.
                    </p>
                    <br />
                    <b>
                        Non Personal Information
                    </b><br />
                    <p>
                        When You use the Website, Our servers automatically record certain information that Your web browser sends whenever You visit any website. These server logs may include information such as Your web request, Internet Protocol (IP) address, browser type, referring/ exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information. We use this information, which does not identify users, to analyze trends, to administer the Website, to track users’ movements around the site and to gather demographic information about the user base as a whole. We do not link this automatically-collected data to personally identifiable information. When You use the Website, We may employ clear web beacons which are used to track Your online usage patterns anonymously. No personally identifiable information from You is collected using these web beacons. In addition, We may also use clear web beacons in HTML-based emails sent to You to track which emails are opened/ viewed. Such collected information is used to enable more accurate reporting and make the Website better for Our users.
                    </p>

                </p>
                <h4 className="second-heading">
                    Cookies
                </h4>
                <p>
                    “Cookies” are small files that reside on Your computer's hard drive and generally contain an anonymous unique identifier and are accessible only by the website that placed them there and not any other sites. We may collect information about Your general internet usage by using a cookie file which is stored on the hard drive of Your computer. Cookies help Us improve Our Website and deliver a better and personalized service. Cookies enable Us:
                    To estimate Our users’ size and usage pattern.
                    To store information about Your preferences, and allow Us to customize Our Website according to Your interests.
                    To speed up Your searches.
                    To recognize You when You return to Our Website.
                    Information about courses offered and prices would be provided in the Website. You agree to pay the price for the courses registered as per the pricing schedule indicated in the Site. At the time of payment, you need to provide accurate information regarding your account, including a valid credit card or debit card or net banking credentials, and you must certify that you are over 18 years of age and legally able to enter into a valid and binding agreement with Quizaro.

                </p>
                <h4 className="second-heading">
                    Use of Information
                </h4>
                <p>
                    We may use the information provided by You in the following ways, viz:
                    Monitor, improve and administer our Website.
                    Conduct research and analysis.
                    Analyze how the Website is used, diagnose service or technical problems, maintain security.
                    Remember information to help You efficiently access the Website.
                    To ensure that content from Our Website is presented in the most effective manner based upon Your interests.
                    To provide You with information that You request from Us.
                    To carry out Our obligations arising from any contracts entered into between You and Us.
                    To notify You about changes on Our Website.
                    To enable Us to comply with Our legal and regulatory obligations.


                </p>
                <h4 className="second-heading">
                    THIRD-PARTY SERVICES
                </h4>
                <p>

                    In general, the third-party providers used by Us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                    However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions. For these providers, we recommend that You read their privacy policies so You can understand the manner in which your personal information will be handled by these providers.
                    Once you leave our Website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy.

                </p>
                <h4 className="second-heading">
                    DISCLOSURE OF INFORMATION
                </h4>
                <p>

                    Quizaro does not sell, rent or otherwise provide your personal or business information to third parties except as provided in this policy. Quizaro may share Your information with third parties with Your prior consent and in such an event, the third parties' use of Your information will be bound by the Policy. We may store information in locations outside the direct control of Quizaro (for instance, on servers or databases co-located with hosting providers).
                    In the event that We sell or buy any business or assets, we may disclose Your information, with Your prior consent, to the prospective seller or buyer of such business or assets. User, email, and visitor information is generally one of the transferred business assets in these types of transactions. We may also transfer or assign such information in the course of corporate divestitures, mergers, or dissolution.
                    We may disclose Your information to service providers involved in operating Our business. This includes payment providers, third party servers, email service providers and professionals such as accountants and lawyers. We reserve the right to disclose your personal information in the scope and in situations as required by the law and when we believe in good faith that such disclosure is necessary to protect our rights, to report suspected illegal activity, comply with judicial proceedings, court orders or decisions, or legal process served on our website.
                    We may also use Your information, and/ or permit selected third parties including service providers, on the Website, with Your prior consent, to use Your information or provide You with such information which may be of interest to You and We and/ or they may contact You about the same. If You wish to be so contacted by the third parties, any information You provide to such third parties may be read, collected, and used by them. Notwithstanding the above mentioned, You may choose to stop receiving such information from the third parties by writing to Us at.

                </p>
                <h4 className="second-heading">
                    YOUR CHOICES ABOUT YOUR INFORMATION
                </h4>
                <p>
                    You may, of course, decline to submit personally identifiable information through the Website, in which case Quizaro may not be able to provide certain services to you. In addition, you may update or correct your account information and email preferences at any time by logging in to your account. If you would like us to remove your personally identifiable information from our database, please send a request to.
                    We are not responsible for revising or removing your personally identifiable information obtained by any third party who has previously been provided your information by us in accordance with this policy or any third party to whom you have provided such information (whether by sharing your login and password, or otherwise).
                    LINKS TO THIRD PARTY SITES
                    Our Website may, from time to time, contain links to and from the websites of Our partner networks, affiliates and other third parties. The inclusion of a link does not imply any endorsement by Us of the third party website, the website's provider, or the information on the third party website. If You follow a link to any of these websites, please note that these websites may be governed by their own privacy policies and We disclaim all responsibility or liability with respect to these policies or the websites. Please check these policies and the terms of the websites before You submit any information to these websites.

                </p>
                <h4 className="second-heading">
                    INFORMATION STORAGE AND SECURITY
                </h4>
                <p>
                    This Policy does not apply to any information other than such information collected otherwise. This Policy shall not apply to any unsolicited information You provide Us through this Website or through any other means. This includes, but is not limited to, information posted to any public areas of the Website. All such unsolicited information shall be deemed to be non-confidential and Quizaro shall be free to use, disclose such unsolicited information without limitation
                </p>
                <h4 className="second-heading">
                    SEVERABILITY
                </h4>
                <p>
                    We have made every effort to ensure that this Policy adheres with the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy.

                </p>
                <h4 className="second-heading">
                    GOVERNING LAW AND DISPUTE RESOLUTION
                </h4>
                <p>
                    This Policy shall be governed by and construed in accordance with the laws of India and the courts at Bengaluru, Karnataka shall have exclusive jurisdiction in relation to any disputes arising out of or in connection with this Policy.
                </p>
                <h4 className="second-heading">
                    FOREIGN JURISDICTION
                </h4>
                <p>
                    Quizaro makes no representation that the content contained in the Website is appropriate or to be used anywhere outside of India. The information provided on or through the contents of the Website is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject the Company to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                </p>
                <h4 className="second-heading">
                    CHANGES TO THE PRIVACY POLICY
                </h4>
                <p>
                    We may change this Policy at any time, in particular as needed to comply with the rules and regulations of the various governmental and regulatory organizations, or to comply with applicable laws and regulations. The revised version will be effective at the time we post it on the website or inform the User. If a User uses the Services or accesses the website after a notice of changes has been sent to such User or published on the Website, such User hereby provides his/her/its consent to the changed terms.
                </p>
                <h4 className="second-heading">
                    CONTACT
                </h4>
                <p>
                    Any required or permitted notices hereunder must be given in writing to the other, by one of the following methods: (a) by electronic mail; (b) sent by facsimile; or (c) sent by registered mail, postage prepaid; or (d) internationally-recognized private express courier. The Company's address for communication is:
                    <ul style={{ padding: "10px" }}>
                        <li>address:No.L 151, 5th Main, Sector 6, HSR Layout, Bangalore - 560102.</li>
                        <li>Phone No: 9380036855
                        </li>
                        <li>email: support@quizaro.in</li>
                    </ul>
                </p>
                <h4 className="second-heading">
                    APPLICABLE LAW AND JURISDICTION
                </h4>
                <p>This Privacy Policy shall be governed by, construed and entered in accordance with the laws of the Republic of India, without regard to choice of law or conflict of law provisions thereof.
                    All disputes with respect to this Privacy Policy shall be brought and heard in the courts situated at Bengaluru, India.
                </p>
            </div>
        </Container>
    )
}

export default PrivacyAndPolicy


