import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Button, Card } from 'ui-neumorphism'
import { ConsoleSqlOutlined, QuestionOutlined } from '@ant-design/icons'
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import RegisterModel from '../global/RegisterModel';

const BookFree = ({ dark, inset }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    return (
        <div className={dark ? "bg-dark book-free-card" : "book-free-card"} style={{
            maxWidth: "1400px",
            margin: "20px auto",
            boxShadow: "none",
        }} >
            <RegisterModel isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <Grid container>
                <Grid item {...{ xs: 12, md: 6 }} >
                    <div className="book-free-left">
                        <Typography variant='h6' my={5} fontWeight={600} >
                            Get The Quizaro Advantage you deserve
                        </Typography>
                        <Button color="#ff7722" onClick={showModal} >
                            Book A Free Demo
                        </Button>
                    </div>
                </Grid>
                <Grid item {...{ xs: 12, md: 6 }} >
                    <div className="book-free-right">
                        <div className="book-free-cards">
                            <Card style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                display: "grid",
                                placeItems: "center"
                            }} >
                                <ConsoleSqlOutlined
                                    style={{
                                        color: "#ff7722",
                                        fontSize: "32px"
                                    }}
                                />

                            </Card>
                            <p className="book-free-cards-text">
                                Online LIVE Classes
                            </p>
                        </div>
                        <div className="book-free-cards">
                            <Card style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                display: "grid",
                                placeItems: "center"
                            }} >
                                <QuestionOutlined
                                    style={{
                                        color: "#ff7722",
                                        fontSize: "32px"
                                    }}
                                />

                            </Card>
                            <p className="book-free-cards-text">
                                Doubt Solving
                            </p>
                        </div>
                        <div className="book-free-cards">
                            <Card style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                display: "grid",
                                placeItems: "center"
                            }} >
                                <CastForEducationIcon
                                    sx={{
                                        color: "#ff7722",
                                        fontSize: "32px"
                                    }}
                                />

                            </Card>
                            <p className="book-free-cards-text">
                                Personal Teacher
                            </p>
                        </div>
                        <div className="book-free-cards">
                            <Card style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                display: "grid",
                                placeItems: "center"
                            }} >
                                <QuizIcon
                                    sx={{
                                        color: "#ff7722",
                                        fontSize: "32px"
                                    }}
                                />

                            </Card>
                            <p className="book-free-cards-text">
                                Test Series
                            </p>
                        </div>
                        <div className="book-free-cards">
                            <Card style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                display: "grid",
                                placeItems: "center"
                            }} >
                                <SchoolIcon
                                    sx={{
                                        color: "#ff7722",
                                        fontSize: "32px"
                                    }}
                                />

                            </Card>
                            <p className="book-free-cards-text">
                                Study Material
                            </p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default BookFree