import { Container, Typography } from '@mui/material'
import React from 'react'
import circleSvg from '../../assets/images/circle.svg'
const MainHeader = () => {
    return (
        <Container maxWidth sx={{
            display: 'grid',
            placeItems: 'center',
            backgroundColor: "#070c23",
            color: "#ffffff",
            height: "auto"
        }}>
            <img src={circleSvg} className='circle-image' alt="circle" />
            <Typography variant='h2' textAlign="center"
                sx={(theme) => ({
                    fontWeight: "600",
                    lineHeight: 1.6,
                    [theme.breakpoints.down("md")]: {
                        fontSize: '3rem'
                    }
                })}
            >
                Unleash Your
            </Typography>
            <Typography variant='h1' textAlign="center"
                sx={(theme) => ({
                    fontWeight: "600",
                    backgroundColor: "#fff",
                    color: "#000000",
                    marginBottom: 10,
                    [theme.breakpoints.down("md")]: {
                        fontSize: '3rem'
                    }
                })}
            >
                Achieving Skills
            </Typography>
        </Container>
    )
}

export default MainHeader