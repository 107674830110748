import { Container, Typography } from '@mui/material'
import React from 'react'
const Personalized = () => {
    return (
        <Container maxWidth className='personalized'>
            <Typography textAlign='center' mt={3} fontWeight={600} variant='h6' textTransform='uppercase' >
                Why Join Quizaro
            </Typography>
            <div className="personalized-container">
                <div className="personalized-item">
                    <div className="personalized-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FPresentation.svg?alt=media&token=03deb361-1094-45b5-a2d0-56d08ecf4f72" alt="" />
                    </div>
                    <div className="personalized-right">
                        <p className="personalized-head">Personalized</p>
                        <p className="personalized-description">Get customized class recommendations based on your interests.</p>
                    </div>
                </div>
                <div className="personalized-item">
                    <div className="personalized-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FImage-Guy.svg?alt=media&token=51a0fc92-e559-4bf9-8a9f-e0276a05e76a" alt="" />
                    </div>
                    <div className="personalized-right">
                        <p className="personalized-head">Thousands of Classes
                        </p>
                        <p className="personalized-description">Build a portfolio of projects that showcases your skills.
                        </p>
                    </div>
                </div>
                <div className="personalized-item">
                    <div className="personalized-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FBest-Product.svg?alt=media&token=f9e61c08-030b-4d86-a78e-5e6101aa56d2" alt="" />
                    </div>
                    <div className="personalized-right">
                        <p className="personalized-head">On-Demand
                        </p>
                        <p className="personalized-description">Watch bite-sized classes on your own schedule, anytime, anywhere on desktop or mobile app.
                        </p>
                    </div>
                </div>
                <div className="personalized-item">
                    <div className="personalized-left">
                        <img src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FConversation.svg?alt=media&token=5502f50f-d7f3-44a7-93b3-6e0aba14bb1b' alt="" />
                    </div>
                    <div className="personalized-right">
                        <p className="personalized-head">Online Community
                        </p>
                        <p className="personalized-description">Ask questions, exchange feedback, and learn alongside other students</p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Personalized