import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { AUTH } from '../config/config';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';



export default function SignUp() {
  const [errors, setErrors] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  const validate = (values) => {
    const errors = {}
    const validateEmailRegex = /^\S+@\S+\.\S+$/
    var isSubmit = true
    if (!values.firstName) {
      errors.firstName = "First Name Required"
      isSubmit = false
    }
    if (!values.lastName) {
      errors.lastName = "Last Name Required"
      isSubmit = false
    }
    if (!values.email) {
      errors.email = "Email Required"
      isSubmit = false
    } else if (!validateEmailRegex.test(values.email)) {
      errors.email = "Invalid Email"
      isSubmit = false
    } else {
      isSubmit = true
    }
    if (!values.password) {
      errors.password = "Password Required"
      isSubmit = false
    } else if (!values.password.length >= 6) {
      errors.password = "Least 6 Character Required"
      isSubmit = false
    } else {
      isSubmit = true
    }
    return [isSubmit, errors]
  }

  const registerWithFirebase = async (values) => {
    setLoading(true)
    try {
      const user = await createUserWithEmailAndPassword(AUTH, values.email, values.password)
      await updateProfile(AUTH.currentUser, {
        displayName: values.firstName,
        emailVerified: true,
      })
      navigate("/")
      console.log({ user });
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log({ error });
    }
  }




  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      email: data.get('email'),
      password: data.get('password'),
      firstName: data.get("firstName"),
      lastName: data.get("lastName")
    }
    if (validate(values)[0]) {
      registerWithFirebase(values)
    } else {
      setErrors(validate(values)[1])
    }
  };

  return (

    <Container component="main" maxWidth="xs" style={{
      minHeight: "100vh"
    }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                error={errors["firstName"]}
                helperText={errors?.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                error={errors["lastName"]}
                helperText={errors["lastName"]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={errors["email"]}
                helperText={errors["email"]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={errors["password"]}
                helperText={errors["password"]}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {
              loading ? <CircularProgress color='secondary' size={30} /> : "Sign Up"
            }
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}