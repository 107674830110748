import { Input, Modal, Space } from "antd";

const RegisterModel = ({ setIsModalOpen, isModalOpen }) => {

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal title="Register" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                okText="Register"
            >
                <Space size='large' direction='vertical' style={{ width: "100%" }} >
                    <Input size="large" placeholder="Name" />
                    <Input size='large' placeholder="Phone" />
                    <Input size="large" placeholder="Email" />
                </Space>
            </Modal>
        </>
    );
};

export default RegisterModel