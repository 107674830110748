import './App.css';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './Pages/Home';
import Layout from './components/global/Layout'
import Contact from './Pages/Contact';
import About from './Pages/About';
import CourseListing from './Pages/CourseListing';
import './config/config'
import Register from './Pages/Register';
import Login from './Pages/Login'
import 'ui-neumorphism/dist/index.css'
import CourseDetails from './Pages/CourseDetails';
import ScrollToTop from './utils/ScrolltoTop';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyAndPolicy from './Pages/PrivacyAndPolicy';
import SuccessStories from './Pages/SuccessStories';



function App() {



  return (
    <Router>
      <ScrollToTop>
        <Layout>
          <Routes>
            <Route path='*' element={<div> <h1>NOT FOUND</h1> </div>} />
            <Route path='/' element={<Home />} />
            <Route path='/hello' element={<Contact />} />
            <Route path='/we-are' element={<About />} />
            <Route path='/courses' element={<CourseListing />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/course-details/:courseId' element={<CourseDetails />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy-and-policy' element={<PrivacyAndPolicy />} />
            <Route path='/success-stories' element={<SuccessStories />} />
          </Routes>
        </Layout>
      </ScrollToTop>
    </Router>
  )

}

export default App;
