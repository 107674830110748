
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import { FacebookOutlined, HeartFilled, InstagramOutlined, LinkedinOutlined, WhatsAppOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Divider, IconButton } from 'ui-neumorphism';
import { MapOutlined } from '@mui/icons-material'
import { Button } from 'antd';
import RegisterModel from './RegisterModel';
import razorPaySvg from '../../assets/razorpay.svg'
import './footer.css'


const pages = [
    {
        label: 'About Us',
        link: "/we-are"
    },
    {
        label: 'Carrier',
        link: '#'
    },
    {
        label: 'Contact Us',
        link: '/hello'
    },
];
const pages1 = [
    {
        label: 'Blogs',
        link: "#"
    },
    {
        label: 'Terms & Conditions',
        link: '/terms-and-conditions'
    },
    {
        label: 'Privacy Polices',
        link: '/privacy-and-policy'
    },
];

const Footer = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const responsive = {
        xs: 12, md: 2
    }
    return (
        <footer style={{
            background: "#b3d5f0",
            color: "#111"
        }}>
            <RegisterModel isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

            <Divider className='util' />
            <Grid container p={3}  >
                <Grid item {...{ xs: 12, md: 4 }} >
                    <Typography fontWeight="700" variant='h5' py={3} >
                        Quizaro Extendededge  PVT  LTD
                    </Typography>
                    <Typography variant='body2' >
                        Quizaro Extendededge offers streamlined learning solution focused on delivering the best-in-class content, faculty, and pedagogy to aspirants, while balancing affordability and industry-relevant learning outcomes for individual learners.
                    </Typography>
                </Grid>
                <Grid item {...responsive} sx={(theme) => ({
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "flex-start",
                    [theme.breakpoints.down('sm')]: {
                        justifyContent: "flex-start"
                    }

                })} >
                    <List>
                        {
                            pages.map(page =>
                                <ListItem>
                                    <Link className='footer-links' to={page.link}>
                                        {page.label}
                                    </Link>
                                </ListItem>
                            )
                        }
                        <ListItem sx={(theme) => ({
                            mt: 7,
                            [theme.breakpoints.down('sm')]: {
                                mt: 3
                            }
                        })} >
                            <Button
                                shape='round'
                                size='medium'
                                type='primary'
                                style={{
                                    background: "#ff7722"
                                }}
                                onClick={showModal}
                            >
                                Become a teacher
                            </Button>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item {...responsive}
                    sx={(theme) => ({
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "flex-start",
                        [theme.breakpoints.down('sm')]: {
                            justifyContent: "flex-start"
                        }

                    })}
                >
                    <List>
                        {
                            pages1.map(page =>
                                <ListItem> <Link className='footer-links' to={page.link}>
                                    {page.label}
                                </Link> </ListItem>)
                        }
                    </List>
                </Grid>
                <Grid item {...{ xs: 12, md: 3 }} >
                    <Typography textTransform="uppercase" fontWeight="700" >
                        Connect
                    </Typography>
                    <List>
                        <ListItem className='contact-links' >
                            <MailOutlined style={{
                                color: "#111",
                                margin: "0 10px"
                            }} />
                            <Link className='footer-links' to='#'>
                                Support@quizaro.in
                            </Link>

                        </ListItem>
                        <ListItem className='contact-links'>
                            <PhoneOutlined style={{
                                color: "#111",
                                margin: "0 10px"

                            }} />
                            <Link className='footer-links' to='#'>
                                9380036855
                            </Link>

                        </ListItem>
                        <ListItem className='contact-links'>
                            <MapOutlined style={{
                                color: "#111",
                                margin: "0 5px"

                            }} />
                            <Link className='footer-links' to='#'>
                                Quizaro Extendededge Private Limited, Bangalore - 560102
                            </Link>

                        </ListItem>
                    </List>
                    <Box display="flex" mx='auto' py={5} justifyContent='space-between' alignItems='center' >
                        <IconButton className='util' text={false} size='large' rounded >
                            <FacebookOutlined style={{
                                color: "#111"
                            }} />
                        </IconButton>
                        <IconButton className='util' text={false} size='large' rounded >
                            <a href="https://instagram.com/quizaroextendededge?igshid=NTc4MTIwNjQ2YQ==">
                                <InstagramOutlined style={{
                                    color: "#111"
                                }} />
                            </a>
                        </IconButton>
                        <a href=" https://www.linkedin.com/company/quizaro-extendededge/">
                            <IconButton className='util' text={false} size='large' rounded >
                                <LinkedinOutlined style={{
                                    color: "#111"
                                }} color='primary' />
                            </IconButton>
                        </a>
                        <IconButton className='util' text={false} size='large' rounded >
                            <a href="https://wa.me/qr/ZKNWI6ZI3WGTJ1">
                                <WhatsAppOutlined style={{
                                    color: "#111"
                                }} color='primary' />
                            </a>
                        </IconButton>
                    </Box>

                </Grid>
            </Grid>

            <Divider className='util' />
            <Box sx={({ breakpoints }) => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 3,
                px: 5,
                color: "#111",
                [breakpoints.down('sm')]: {
                    flexDirection: "column"
                }
            })}
            >
                <div className="secure-payments-container">
                    <p>100% safe and secure payments</p>
                    <div className="payments-image-container">
                        <img
                            className='payments-image'
                            src={razorPaySvg} alt="" />
                        <img
                            className='payments-image'
                            src="https://www.instamojo.com/wp-content/uploads/2021/04/instamojo_logo.png.webp" alt="" />
                        <img
                            className='payments-image'
                            src='https://cashfreelogo.cashfree.com/website/NavFooter/Cashfree-Dark.svg' alt="" />

                    </div>
                </div>
                <div className="secure-payments-container">
                    <div className="payments-image-container">
                        <img
                            className='payments-image'
                            src="https://www.startupindia.gov.in/content/dam/invest-india/newhomepage/Logo1.png" alt="" />
                        <img
                            className='payments-image'
                            src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Ministry_of_Corporate_Affairs_India.svg/500px-Ministry_of_Corporate_Affairs_India.svg.png' alt="" />
                        <img
                            className='payments-image'
                            style={{
                                mixBlendMode: "multiply"
                            }}
                            src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Fiso.jpeg?alt=media&token=d19a1b61-8a20-47d9-b917-30329f5ed25f' alt="" />
                        <img
                            style={{
                                mixBlendMode: "multiply"
                            }}
                            className='payments-image'
                            src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-08%20at%202.53.46%20PM.jpeg?alt=media&token=983f67e8-1f1d-4a3c-b0a8-6841ebea24b2' alt="" />

                    </div>
                </div>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                background: "#2f2f2f",
                alignItems: "center",
                padding: 3,
                px: 5,
                color: "#fff"
            }}
            >
                <Typography textTransform='capitalize'>
                    &copy; Quizaro all rights are reserved {new Date().getFullYear()}
                </Typography>
                <Typography variant='subtitle1' sx={{ opacity: 0 }} display='flex' justifyContent='center' alignItems='center' >
                    <a href="https://mnemonicx.com">
                        Built By Mnemonicx <HeartFilled style={{
                            color: "crimson",
                            fontSize: 20,
                            margin: "0 5px"
                        }} />
                    </a>
                </Typography>
            </Box>
        </footer>
    )
}

export default Footer