import React from 'react'
import './whyus.css'
import { Container } from '@mui/material'

const WhyUS = () => {
    return (
        <div class="why-choose-us">
            <Container maxWidth>
                <div className='why-us-container'>
                    <div className='left'>
                        <h1><span class="blue-text">Why</span> Choose Us ?</h1>
                        <hr class="underline" />
                        <p class="choose-p">Welcome to Quizaro, your trusted partner in the world of online education. As a leading edtech company, we have built a reputation for excellence and innovation. Here's why you should choose Quizaro:
                            
                        </p>
                        <div class="points">
                            <p><span><img src="https://verzeo.com/v3/images/home/students.svg" alt="book" /></span>
                                98731+ students trained</p>
                            <p><span><img src="https://verzeo.com/v3/images/home/hour.svg" alt="book" /></span>
                                78+ Cr hours of training</p>
                            <p><span><img src="https://verzeo.com/v3/images/home/courses.svg" alt="book" /></span>
                                36+ specialized professional courses</p>
                        </div>

                    </div>
                    <div class="img-section">
                        <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FMonitor.svg?alt=media&token=3dbd7e5d-c092-431d-a1b1-343c9b537e9a" alt="quizaro" class="left-img" />
                    </div>
                </div>

            </Container>
        </div>)
}

export default WhyUS