import { Box, Container, styled, Typography } from '@mui/material'
import React from 'react'
import googleSvg from '../../assets/google.svg'
import indeedSvg from '../../assets/indeed.svg'
import linkedinSvg from '../../assets/linkedin.svg'


const CompanyLogo = styled('img')(({ theme }) => ({
    color: theme.palette.primary.light,
    width: "120px",
    objectFit: "contain",
    margin: "2rem",
    [theme.breakpoints.down('sm')]: {
        margin: "2rem auto"
    }
}))

const CompanyBox = styled(Box)(({ theme }) => ({
    display: 'inline-grid',
    placeItems: "center",
    [theme.breakpoints.down("sm")]: {
        display: "grid"
    }
}))

const textStyle = {
    color: "#f8d734"
}

const OurPartners = () => {
    return (
        <section className='our-partners ' style={{
            padding: "0.9rem"
        }}>
            <Typography variant='h4' fontWeight="700" textTransform="uppercase" textAlign="center">
                Get<Typography component='span' variant='h4' fontWeight="700" textTransform="uppercase" > Hired</Typography> IN
            </Typography>
            <Container sx={(theme) => ({
                py: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column"
                }
            })} >
                <CompanyBox>
                    <CompanyLogo src={googleSvg} />
                    <Typography fontWeight={600} sx={textStyle} >
                        1000+
                    </Typography>
                </CompanyBox>
                <CompanyBox>
                    <CompanyLogo src={indeedSvg} />
                    <Typography fontWeight={600} sx={textStyle}>
                        2000+
                    </Typography>
                </CompanyBox>
                <CompanyBox>
                    <CompanyLogo src={linkedinSvg} />
                    <Typography fontWeight={600} sx={textStyle}>
                        1000+
                    </Typography>
                </CompanyBox>
                <CompanyBox>
                    <CompanyLogo src="https://media.foundit.in/trex/public/theme_3/src/assets/images/header/companyLogo.svg" />
                    <Typography fontWeight={600} sx={textStyle}>
                        2200+
                    </Typography>
                </CompanyBox>
                <CompanyBox>
                    <CompanyLogo src="https://static.naukimg.com/s/4/100/i/naukri_Logo.png" />
                    <Typography fontWeight={600} sx={textStyle}>
                        600+
                    </Typography>
                </CompanyBox>


            </Container>
        </section>
    )
}

export default OurPartners