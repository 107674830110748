import { Container, Grid, Typography } from '@mui/material';
import WeDo from '../components/home/WeDo';
import OurPartners from '../components/home/OurPartners';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui-neumorphism';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { Space } from 'antd';
import { LineChartOutlined, EditOutlined } from '@ant-design/icons'
import OurServices from '../components/home/OurServices';
import Personalized from '../components/home/Personalized';
import RatingAndReviews from '../components/courseListing/RatingAndReviews';
import Hiring from '../components/home/Hiring';
import WhyUS from '../components/home/WhyUS';

const campusImages = ["https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.22.47%20PM.jpeg?alt=media&token=5620d04e-51ba-40f0-b151-97ecd5f6e5fa", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.22.47%20PM%20(1).jpeg?alt=media&token=99fbbbe0-30f0-4179-a8b5-f925e772391d", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.22.47%20PM%20(2).jpeg?alt=media&token=9fc941ee-1e8c-4ca9-929d-36285d3173f7", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.22.48%20PM.jpeg?alt=media&token=2c887568-a1da-4d06-87f8-5bde555c497c", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.22.48%20PM%20(1).jpeg?alt=media&token=7e83eb6c-23e2-4013-a8e2-4f0896992b12", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.22.48%20PM%20(2).jpeg?alt=media&token=6f92eb26-df80-40ec-ba38-69e7dfa5e8d3", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.25.12%20PM.jpeg?alt=media&token=bc9d0d1f-825f-48d8-9f7a-7af44b594b42", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.25.12%20PM%20(1).jpeg?alt=media&token=a031c6bf-04f3-4fdb-a022-6d343888395f"]


const companyImages = ["https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.26.23%20PM.jpeg?alt=media&token=67947e62-af20-4c70-83f0-93678de0fc8b", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.26.23%20PM%20(1).jpeg?alt=media&token=bae04ac2-a2bc-4e78-8b57-5f69cfc13b90", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.26.23%20PM%20(2).jpeg?alt=media&token=01f82ad8-546d-4a66-b1fa-d464751f20eb", "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-08-17%20at%206.26.23%20PM%20(3).jpeg?alt=media&token=370d7007-9a21-4c76-bcfa-bf3ce483ef94"]


function Home() {
    const navigate = useNavigate()
    return (
        <main className="App">
            <Grid container
                className='heroImage'
                style={{
                    // background: "var(--bold_light)",
                    position: "relative",
                    isolation: "isolate"
                }} >
                <Grid item {...{ xs: 12, md: 6 }} sx={{
                    display: "grid",
                    placeItems: "center",
                    px: 3
                }} >
                    <p className="heroText hero-color-text">
                        What you choose ,what you think and what you do.. Is you become
                    </p>
                </Grid>
            </Grid >
            <Hiring images={campusImages} />
            <Hiring rtl={true}
                images={companyImages}
                head="Our Partner Companies"
                desc="Expert-led, Diverse Courses, Anytime Access. Enroll Today!"
            />

            <WhyUS />
            <section className="comDetails">
                <div className='details' >
                    <Space className="details-items" size='middle'  >
                        <WorkOutlineIcon sx={{
                            color: "#ff7722"
                        }} fontSize='large' />
                        <Typography variant='h6' sx={{
                            display: "flex",
                            flexDirection: 'column'
                        }} >
                            Jobs in 1000+
                            <Typography variant='caption' >
                                Top Companies
                            </Typography>
                        </Typography>
                    </Space>
                    <Space className="details-items" size='middle' >
                        <LineChartOutlined
                            style={{
                                color: "#ff7722",
                                fontSize: "30px"
                            }}
                        />
                        <Typography variant='h6' sx={{
                            display: "flex",
                            flexDirection: 'column'
                        }} >
                            50%
                            <Typography variant='caption' >
                                Average Hike
                            </Typography>
                        </Typography>
                    </Space>
                    <Space className="details-items" size='middle' >
                        <EditOutlined style={{
                            color: "#ff7722",
                            fontSize: "30px"
                        }} />
                        <Typography variant='h6' sx={{
                            display: "flex",
                            flexDirection: 'column'
                        }} >
                            Top 1%
                            <Typography variant='caption' >
                                Global Universities
                            </Typography>
                        </Typography>
                    </Space>
                </div>
                <Button depressed active size='medium' onClick={() => navigate("/courses")} >
                    Explore Our Courses
                </Button>
            </section>
            <RatingAndReviews />
            <Container sx={{
                py: 3,

            }} maxWidth className='ServiceAndTech' >
                {
                    <Typography textAlign='center' pb={5} fontWeight={600} variant='h5' >
                        Our Global Extended Ecosystem
                    </Typography>
                }
                <Grid container p={{ xs: 1, md: 3 }} >
                    <Grid item {...{ xs: 12, md: 6 }} className="image">
                        <div className="cardImage">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FAsset%201.svg?alt=media&token=124f4811-52a6-4f06-9617-2d05d2446751" alt="" />
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 6 }} className="image">
                        <div className="cardDetails">
                            <p className="cardTitle">
                                Service & Technology partner
                            </p>
                            <p className="cardDescription">
                                Get involved! Help build and support Beginners for their careers to open new Quizaro innovation
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container p={{ xs: 1, md: 3 }}>
                    <Grid item {...{ xs: 12, md: 6 }} className="image">
                        <div className="cardDetails">
                            <p className="cardTitle">
                                Community
                            </p>
                            <p className="cardDescription">
                                Get started! Connect with 3rd party support and knowledge to keep your LMS Extended – edge
                                Easily and confidently scale from supporting Beginners learning to thousands of simultaneous learners. · Empower learners and instructors                                </p>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 6 }} className="image">
                        <div className="cardImage">
                            <img src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FSmall-Character%20Illustration.svg?alt=media&token=17254ad1-e023-4929-a8d5-a5b6943b5d64' alt="" />
                        </div>
                    </Grid>
                </Grid >
                <Typography textAlign='center' component='div' width='60%' mx='auto' my={3} variant='body2'  >
                    Easily and confidently scale from supporting small learning to thousands of simultaneous learners
                </Typography>
            </Container>
            <Personalized />
            <OurServices />
            <WeDo />
            <OurPartners />

        </main>
    );
}

export default Home;
