import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'ui-neumorphism'

const WeDo = () => {
    const navigate = useNavigate()
    return (
        <section className='weDo px'>
            <Typography variant='h6' textTransform="uppercase" textAlign='center' fontWeight={600} pb={3} >
                Too strong Brand, one extended vision
            </Typography>
            <p className='weDo-text'>
                Quizaro for a better future for our learners, where we offer tailored learning programs. Standard educational content created by industry experts is distributed online through our platform across the globe at an affordable rate.
                We aim to deliver our mantra “keep learning keep growing” to every household that is brewing an aspiring careerist. Education is the most powerful weapon for changing the world.
            </p>
            <Button
                onClick={() => navigate('/we-are')}
                depressed style={{
                    margin: "30px auto"
                }} size='large' >
                More About us
            </Button>
        </section>
    )
}

export default WeDo