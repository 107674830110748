import { Button, Modal, Space, ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import { WhatsAppOutlined } from '@ant-design/icons'
import './requestCallback.css'

const RequestCallback = () => {
    const [open, setOpen] = useState(true);
    useEffect(() => {
        if (localStorage.getItem("visited")) {
            setOpen(false)
        }
    }, [])

    const handleCancel = () => {
        setOpen(false);

    };
    return (
        <>
            <Modal title="" footer={null} open={open} onCancel={handleCancel}
            >
                <div className="request-callback-container">
                    <div className="request-left">
                    </div>
                    <div className="request-right">
                        <p className="request-head">
                            Talk to Our career experts
                        </p>
                        <p className="request-description">
                            we love to hear from you
                        </p>
                        <Space style={{ marginTop: 20 }} direction="vertical" align="center" >
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: "#25D366",
                                    },
                                }}
                            >
                                <Button
                                    type="primary"
                                    size="large"
                                    href="https://wa.me/message/7MCYX7YP5ALAL1"
                                    icon={<WhatsAppOutlined />}
                                >
                                    Whatsapp us
                                </Button>
                            </ConfigProvider>

                            <p>Or</p>
                            <Button
                                size="large"
                                type="primary"
                                style={{
                                    width: "100%"
                                }}
                            >
                                Request Callback
                            </Button>
                        </Space>
                    </div>
                </div>

            </Modal>
        </>
    );
};

export default RequestCallback