import React from 'react'
import './ratingsAndReviews.css'
import googleLogo from '../../assets/google.svg'
import { Rate } from 'antd'

const RatingAndReviews = () => {
  return (
    <section  >
      <div className="rating-container">
        <div className="rating-left">
          <p className="rating-head">
            Ratings & Testimonials by Learners
          </p>
          <p className="rating-description">
            Quizaro has received honest feedback from our learners around the globe.
          </p>
          <div className="rating-google-rating">
            <div className="google-logo">
              <img src={googleLogo} alt='google logo' />
            </div>
            <div className="google-rating">
              <p className="rating">
                4.8
              </p>
              <Rate value={4.8} allowClear={false} allowHalf disabled />
            </div>
          </div>
        </div>
        <div className="rating-right">
          <div className="review-container">
            <div className="review-head">
              <img src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-06-25%20at%209.17.36%20AM.jpeg?alt=media&token=bd3756cd-423d-4d73-84cf-b6627dc23b5c' alt="" className="review-image" />
              <div className="review-info">
                <p className="review-name">
                  N SRINIVAS
                </p>
                <p className="review-course">
                  <span>company</span>: NATWEST GROUP (former ROYAL BANK OF SCOTLAND)
                </p>
              </div>
            </div>
            <p className="review-description">
              Quizaro is an innovative edtech company that offers a comprehensive platform for interactive learning. The company's name, Quizaro, is catchy and reflects the core focus on quizzes and educational assessments. The platform provides a wide range of educational resources, including interactive quizzes, multimedia content, and progress tracking features.
              One of the notable strengths of Quizaro is its user-friendly interface, which allows students and educators to easily navigate and engage with the platform. The intuitive design promotes a seamless learning experience, making it accessible for users of all ages and technical backgrounds.
            </p>
          </div>
          <div className="review-container">
            <div className="review-head">
              <img src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-06-25%20at%209.17.36%20AM%20(1).jpeg?alt=media&token=cbfd2fb5-1dc5-4eaa-ad70-8e03418501c4' alt="" className="review-image" />
              <div className="review-info">
                <p className="review-name">
                  M Subramaniyan
                </p>
                <p className="review-course">
                  <span>company</span>: Tata Consultancy Services Limited
                </p>
              </div>
            </div>
            <p className="review-description">
              Quizaro stands out for its extensive library of educational content across various subjects and grade levels. The company's commitment to providing high-quality and curriculum-aligned materials is evident, offering students a diverse range of resources to enhance their learning journey.Another commendable aspect of Quizaro is its adaptive learning capabilities. The platform utilizes personalized learning algorithms to tailor content based on individual student needs, ensuring targeted instruction and maximizing learning outcomes. This adaptability promotes student engagement and fosters a dynamic learning environment.
            </p>
          </div>
          <div className="review-container">
            <div className="review-head">
              <img src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-06-25%20at%209.17.37%20AM.jpeg?alt=media&token=d5a8c036-d736-4b49-81ab-51d17d9b231e' alt="" className="review-image" />
              <div className="review-info">
                <p className="review-name">
                  Hari Balaji A
                </p>
                <p className="review-course">
                  <span>company</span>: Zoho Corporation Pvt Ltd
                </p>
              </div>
            </div>
            <p className="review-description">
              Quizaro is an innovative and user-friendly edtech platform that offers interactive quizzes, a diverse range of educational content, and personalized learning. With a strong emphasis on data privacy and security, Quizaro provides a safe and trusted learning environment. Enhancements in social learning features and integration with learning management systems could further enrich the platform's educational value

            </p>
          </div>
          <div className="review-container">
            <div className="review-head">
              <img src='https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FWhatsApp%20Image%202023-06-25%20at%209.17.37%20AM%20(1).jpeg?alt=media&token=43a6fe62-acdb-4cb9-b48c-256869c2ae14' alt="" className="review-image" />
              <div className="review-info">
                <p className="review-name">
                  Arun Kumar S
                </p>
                <p className="review-course">
                  <span>company</span>:Cisco
                </p>
              </div>
            </div>
            <p className="review-description">
              Quizaro, an innovative player in the edtech industry, is making waves with its cutting-edge platform for interactive learning. With a cleverly designed interface that promotes seamless navigation, Quizaro offers students and educators an engaging and intuitive educational experience. The platform's rich library of educational resources spans a variety of subjects and grade levels, empowering learners with diverse content options.
              What sets Quizaro apart is its personalized approach to learning. By harnessing the power of adaptive algorithms, the platform tailors the learning journey to meet each student's unique needs, ensuring targeted instruction and optimized learning outcomes.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RatingAndReviews