import React from 'react'
import './search.css'
import { SearchOutlined } from '@ant-design/icons'

const Search = () => {
    return (
        <div className="search">
            <input className='search-input' placeholder='Search' type="search" name="" id="" />
            <div className="search-icon">
                <SearchOutlined style={{ fontSize: 20 }} />
            </div>
            <div className="search-suggestions">
                <div className="most-popular">
                    <p>Most Popular</p>
                    <div className="image-suggestions">
                        <img src='https://culture-rh.com/wp-content/uploads/2022/08/data-science.jpg' alt="" className="image" />
                        <p>Data Science
                            <br />
                            <span>quizaro</span>
                        </p>

                    </div>
                    <div className="image-suggestions">
                        <img src='https://bernardmarr.com/wp-content/uploads/2021/08/Should-I-Choose-Machine-Learning-or-Big-Data.jpg' alt="" className="image" />
                        <p>Machine Learning
                            <br />
                            <span>quizaro</span>
                        </p>
                    </div>
                    <div className="image-suggestions">
                        <img src='https://www.businessofapps.com/wp-content/uploads/2020/08/zymr8_steps_the_mobile_app_dev_lifecycle_cover.jpg' alt="" className="image" />
                        <p>Android Development
                            <br />
                            <span>quizaro</span>
                        </p>
                    </div>
                </div>
                <div className="popular-now">
                    <p>Popular right now</p>
                    <div className="icon-suggestions">
                        <SearchOutlined />
                        <p>Web development
                        </p>
                    </div>
                    <div className="icon-suggestions">
                        <SearchOutlined />
                        <p>Artificial intelligence with python
                        </p>
                    </div>
                    <div className="icon-suggestions">
                        <SearchOutlined />
                        <p>Amazon web services</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search