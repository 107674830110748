
import React, { useState, useEffect } from 'react';
import { Box, List, ListItemButton, ListItemIcon, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import { Close, Home, } from '@mui/icons-material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import InfoIcon from '@mui/icons-material/Info';
import { AUTH, db } from '../../config/config';
import { motion, useCycle } from 'framer-motion'
import { IconButton } from 'ui-neumorphism';
import { UserOutlined, MenuFoldOutlined, RightOutlined, GroupOutlined, BookOutlined } from '@ant-design/icons';
import logoImage from '../../assets/bitmap.svg'
// import { coursesList } from '../../Pages/cources';
import './navbar.css'
import Search from './Search';
import { collection, getDocs, query } from 'firebase/firestore';


const pages = [
    {
        label: 'Home',
        link: "/"
    },
    {
        label: 'Courses',
        link: '/courses'
    },
    {
        label: 'Success Stories',
        link: "/success-stories"
    },
    {
        label: 'About',
        link: '/we-are'
    },
    {
        label: "Hello",
        link: "/hello"
    }

];

const variants = {
    open: {
        // left: 0,
        clipPath: `circle(${window.innerHeight * 2 + 200}px at 40px 40px)`,
        height: "100vh",
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }

    },
    closed: {
        clipPath: "circle(0px at 40px 40px)",
        height: 0,
        transition: {
            type: "spring",
            stiffness: 400,
            damping: 40
        },
    }
}



function Navbar() {
    const [open, setOpen] = useCycle(false, true)
    const navigate = useNavigate()
    const [hovered, setHovered] = useState('')
    const [categoriesList, setCategoriesList] = useState([])

    useEffect(() => {
        const getCategory = async () => {
            const q = query(collection(db, "Category"));
            const querySnapshot = await getDocs(q);
            const data = []
            querySnapshot.forEach((doc) => {
                data.push(doc.data())
            })
            setHovered(data[0].label);
            setCategoriesList(data);
        }
        getCategory()

    }, [])
    return (
        <>
            <motion.nav
                anchor="bottom"
                initial={{
                    position: "fixed",
                    left: 0,
                    zIndex: 999,
                    width: "70vw",
                }}
                variants={variants}
                animate={open ? "open" : "closed"}

            >
                <Box minHeight="100%"
                    sx={{
                        py: 2
                    }}
                    bgcolor='var(--bold_black)' >
                    <div className="logo"
                        style={{
                            margin: "10px"
                        }}  >
                        QUIZARO
                        <Typography variant='caption' sx={{ mx: 1 }} >
                            EXTENDEDEDGE
                        </Typography>
                    </div>
                    <List sx={{
                        px: 3,
                        color: "white"
                    }} >
                        <ListItemButton
                            onClick={() => {
                                setOpen()
                                navigate('/')
                            }}

                        >
                            <ListItemIcon>
                                <Home sx={{
                                    fill: "#fff"
                                }} />
                            </ListItemIcon>
                            Home
                        </ListItemButton>

                        <ListItemButton
                            onClick={() => {
                                setOpen()
                                navigate('/courses')
                            }}

                        >
                            <ListItemIcon>
                                <LocalLibraryIcon sx={{
                                    fill: "#fff"
                                }} />
                            </ListItemIcon>
                            Courses
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                setOpen()
                                navigate('/success-stories')
                            }}

                        >
                            <ListItemIcon>
                                <LocalLibraryIcon sx={{
                                    fill: "#fff"
                                }} />
                            </ListItemIcon>
                            Success Stories
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                setOpen()
                                navigate('/we-are')
                            }}

                        >
                            <ListItemIcon>
                                <InfoIcon sx={{
                                    fill: "#fff"
                                }} />
                            </ListItemIcon>
                            What we are
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                setOpen()
                                navigate('/hello')
                            }}

                        >
                            <ListItemIcon>
                                <ConnectWithoutContactIcon sx={{
                                    fill: "#fff"
                                }} />
                            </ListItemIcon>
                            Hello
                        </ListItemButton>
                    </List>
                </Box>
            </motion.nav>
            <nav className="navbar">
                <div rounded
                    active
                    color="#fff"
                    size='large'
                    style={{
                        "--bg-color": "#111936",
                        "--light-bg-dark-shadow": "#080b18",
                        "--light-bg-light-shadow": "#1a2754",
                    }}
                    text={false} className="logo">
                    <a href="/"><img src={logoImage} className='logoImage' alt='logo' /></a>
                </div>
                <Search />
                <ul className="menu">
                    {
                        pages.map((menuItems) => <li className='menuItems'>
                            <Link className='menu-links' preventScrollReset={true} to={menuItems.link} >
                                {menuItems.label === "Courses" && <div className='new'>new</div>} {menuItems.label}
                            </Link>
                            {
                                menuItems.label === "Courses" &&
                                <div className="sub-menu">
                                    <div className='nav-categories'>
                                        <p>CHOOSE A CATEGORY</p>
                                        {categoriesList?.map(ele =>
                                            <span onMouseOver={() => setHovered(ele.label)} >
                                                <Link className='menu-links' preventScrollReset={true} to={`/course-details/${ele.label}`} >
                                                    <GroupOutlined style={{ color: '#ff4400' }} />
                                                    {ele.title}
                                                    <RightOutlined className='arrow-icon' />
                                                </Link>
                                            </span>
                                        )}
                                    </div>
                                    <div className='nav-course-item'>
                                        <p>POPULAR COURSES</p>
                                        {categoriesList?.filter(item => item.label === hovered)[0]?.courses?.map(ele =>
                                            <span className='nav-course-item-title'>
                                                <BookOutlined style={{ color: '#ff4400' }} />
                                                {ele.name}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            }
                        </li>)
                    }
                </ul>

                <IconButton rounded color='#fff' active size='large' text={false}
                    style={{
                        "--bg-color": "#111936",
                        "--light-bg-dark-shadow": "#080b18",
                        "--light-bg-light-shadow": "#1a2754",
                    }}
                    className='profileIcon' onClick={() => {
                        navigate("/login")
                    }} >
                    {
                        AUTH.currentUser ? <UserOutlined /> : <UserOutlined color="secondary" />
                    }
                </IconButton>

                <IconButton
                    rounded
                    text={false}
                    style={{

                        "--bg-color": "#111936",
                        "--light-bg-dark-shadow": "#080b18",
                        "--light-bg-light-shadow": "#1a2754",
                    }}
                    onClick={() => setOpen()}
                    active
                    className='mobileMenu'
                    size='large'
                    color='#fff'
                >
                    {
                        !open ? <MenuFoldOutlined /> : <Close />
                    }
                </IconButton>
            </nav >
        </>
    );
}
export default Navbar;