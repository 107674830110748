import ContactForm from '../components/global/ContactForm'

const Contact = () => {
  return (
    <main>
      <section className="header">
        <div className="hero">

          <div className="heading">
            <h3>
              Reach Us
            </h3>
            <p>
              we teach better
            </p>
          </div>
        </div>
      </section>
      <section className='contactForm'>
        <ContactForm title="Let's work together" />
      </section>
    </main>
  )
}

export default Contact