import { Box, Container, Grid, Stack, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import RealtimeImage from '../assets/realword.svg'
import TimeImage from '../assets/time.svg'
import ResumeImage from '../assets/resume.svg'
import { Button, Card, IconButton } from 'ui-neumorphism';
// import { coursesList } from './cources';
import { Carousel, List, Space, Tag } from 'antd';
import { CheckCircleFilled, FacebookOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, PhoneOutlined, WechatOutlined, WhatsAppOutlined } from '@ant-design/icons';
import certificateImage from '../assets/certificate.png'
import RegisterModel from '../components/global/RegisterModel';
import IbmSvg from '../assets/icons8-ibm.svg'
import adobeSvg from '../assets/adobe.svg'
import googleSvg from '../assets/google.svg'
import CourseCard from '../components/courseDetails/CourseCard';
import { query, collection, getDocs, where } from 'firebase/firestore'
import { db } from '../config/config'
import '../components/courseListing/InstructorProfile.css'

const freeprice = [
  {
    status: true,
    title: "Career Assistance for PG joiners across India."
  },
  {
    status: true,
    title: "Masters in Abroad country Including (Documentation, college shortlist, scholarship's)"
  },
  {
    status: true,
    title: "Free Stipend paid Internship."
  },
  {
    status: true,
    title: "Job Assistance "
  },
  {
    status: true,
    title: "Free webinar session from Industrial mentors"
  },

]
const selfprice = [

  {
    status: true,
    title: " Specialization in domain completion certificate"
  },
  {
    title: " Case Study & Research Methodology",
    status: true
  },
  {
    title: "One Minor Project & One Major Project",
    status: true
  },
  {
    title: "Project Completion Certificate",
    status: true
  }, {
    title: "Training and Program Completion Certificate",
    status: true
  }, {
    title: 'Up to 4 hours mentor training assistance',
    status: true
  }
]
const mentorleadprice = [

  {
    status: true,
    title: "Specialization in domain completion certificate"
  },
  {
    title: "Case Study & Research Methodology",
    status: true
  },
  {
    title: "One Minor Project & One Major Project",
    status: true
  },
  {
    title: "Project Completion Certificate",
    status: true
  }, {
    title: "Training and Program Completion Certificate",
    status: true
  }, {
    title: '16 + hours mentor training & project assistance',
    status: true
  }
]

const certificateList = [
  {
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FJone%20-%20A4%20(Landscape).png?alt=media&token=9bac3db9-6c92-45b9-9b44-395883a33e02",
    title: "Specialization in domain completion certificate",
    description: ["Domain Completion Certification", "Extraordinary Performance Delivered", "Regarding specialization in Domain"]
  },
  {
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FJone%20-%20A4%20(Landscape)%203.png?alt=media&token=64583505-411a-40fb-9f75-5c999a339a38",
    title: "Training And Program Course Completion Certificate",
    description: [" Certificates with Industry Verification", "Boost your resume", " Performance-Based Acknowledgement", "Realize Excellence in Your Domain"]
  },
  {
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2FJone%20-%20A4%20(Landscape)%202.png?alt=media&token=abcc944d-93c0-4511-ab58-28ad5a4e0968",
    title: "Project Completion Certificate",
    description: ["Timely Project Completion Award", "Delivery of Excellent Performance", "Skilled Based Projects", "Experts Guided Projects"]
  },
  {
    title: " LOR - Letter of Recommendation",
    description: ["Earn a scholarship", "Pursuing a new job", "Create leadership position for a group"]
  }
]

const ContactFormHolder = styled(Box)(({ theme }) => ({
  width: '80%',
  margin: "0 auto",
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: "column",
  gridRowGap: theme.spacing(2.3),
  [theme.breakpoints.down('sm')]: {
    width: "100%"
  }
}))


const CustomTextFelid = styled('input')(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  // background: 'transparent',
  padding: theme.spacing(1.2),
  border: 0,
  borderRadius: theme.shape.borderRadius,
  color: "#111",
  background: "#b3d5f0",
  boxShadow: `inset 10px 10px 20px #98b5cc,
            inset -10px -10px 20px #cef5ff`,
  outline: '0',
  '&focus': {
    outline: "none",
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
}))


const TextArea = styled('textarea')(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  color: '#111',
  padding: theme.spacing(2),
  border: 0,
  borderRadius: theme.shape.borderRadius,
  background: "#b3d5f0",
  boxShadow: `inset 10px 10px 20px #98b5cc,
            inset -10px -10px 20px #cef5ff`,
  outline: '0',
  '&focus': {
    outline: "none",
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
}))
const CourseDetails = () => {
  const { courseId } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [course, setCourse] = useState([])

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const ref = useRef(null)

  useEffect(() => {
    const getCategory = async () => {
      const q = query(collection(db, "Category"), where("label", '==', courseId));
      const querySnapshot = await getDocs(q);
      setCourse(querySnapshot.docs[0].data())
    }
    getCategory()

  }, [courseId])





  return (
    <>

      <Container maxWidth style={{ color: "#FEFAE0", background: "#070c23" }}>
        <div className="welcome-container">
          <p className="Revolutionizing">Revolutionizing</p>
          <p className='welcome-desc'>
            Welcome to the future of learning, where Quizaro is conquering boredom and soaring academic achievements!
          </p>
        </div>
      </Container>
      <section sx={{
        background: '#070c23'
      }} maxWidth >
        <Grid container
          className='course-details-main'
          sx={{
            background: "rgba(0,0,0,0.4)",
            backgroundImage: `url(${course?.bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "soft-light",
            boxShadow: `inset 0 1px 3px 0 rgb(0 0 0 / 0.1),0 1px 2px -1px rgb(0 0 0 / 0.1)`,
          }} >
          <Grid item  {...{ xs: 12, sm: 8, }} justifyContent="center" alignItems="center" >
            <div className="course-hero-left">
              <p className="course-hero-left-heading">
                {course.head}
              </p>
              <div className="course-hero-left-description" style={{
                color: '#fff'
              }}>
                {course.top_description}
              </div>
            </div>
          </Grid>
          <Grid item  {...{ xs: 12, sm: 4, }} className="course-details-bg" >
            <div className="course-hero-right">
              <ContactFormHolder ref={ref} >
                <Card className="contact-form" inset style={{
                  width: "100%",
                  padding: '2rem',
                }} >
                  <Box display="flex" justifyContent='space-between' alignItems='center' >
                    <IconButton
                      text={false} size='large' rounded >
                      <FacebookOutlined style={{
                        color: "#111"
                      }} />
                    </IconButton>
                    <IconButton
                      text={false} size='large' rounded >
                      <a href="https://instagram.com/quizaroextendededge?igshid=NTc4MTIwNjQ2YQ==">
                        <InstagramOutlined style={{
                          color: "#111"
                        }} />
                      </a>
                    </IconButton>
                    <a href=" https://www.linkedin.com/company/quizaro-extendededge/">
                      <IconButton

                        text={false} size='large' rounded >
                        <LinkedinOutlined style={{
                          color: "#111"
                        }} color='primary' />
                      </IconButton>
                    </a>
                    <IconButton

                      text={false} size='large' rounded >
                      <a href="https://wa.me/qr/ZKNWI6ZI3WGTJ1">
                        <WhatsAppOutlined style={{
                          color: "#111"
                        }} color='primary' />
                      </a>
                    </IconButton>
                  </Box>
                  <Stack my={4} spacing={3} >
                    <CustomTextFelid
                      placeholder='Email'
                      prepend={<MailOutlined style={{
                        fontSize: 20,
                        color: "#111"
                      }} />}
                      width={ref.current && ref.current.innerWidth}
                    />
                    <CustomTextFelid
                      placeholder='Phone'
                      prepend={<PhoneOutlined style={{
                        fontSize: 20,
                        color: "#111"
                      }} />}
                      inputStyles={{

                      }}

                    />
                    <TextArea
                      width={ref.current && ref.current.innerWidth}
                      prepend={<WechatOutlined style={{
                        fontSize: 20,
                        color: "#111"
                      }} />}
                      rows={5} variant='standard' label="Message">
                      Message
                    </TextArea>
                    <Button
                      variant='contained'>
                      Reach US
                    </Button>
                  </Stack>
                </Card>
              </ContactFormHolder>
            </div>
          </Grid>
        </Grid>
      </section>
      <Container maxWidth className=' bg-dark' >
        <div className="course-details-second-container">
          <p className="course-details-second-head">
            Classes come first , but skill is close second
          </p>
          <p className="course-details-second-description">
            Quizaro has indexed over million job description for you and has pinpointed the most in demand skills employers are looking for.
          </p>
        </div>
      </Container>
      <Container maxWidth >
        <div className="course-details-third-container"  >
          <Grid container>
            <Grid item {...{ xs: 12, sm: 6, md: 4 }} >
              <div className="third-card-container">
                <img src={RealtimeImage} alt="" className="third-img" />
                <p className="third-head">
                  Real World Examples
                </p>
                <p className="third-description">
                  Learn practical skills with interactive modules and knowledge evaluation!

                </p>
              </div>
            </Grid>
            <Grid item {...{ xs: 12, sm: 6, md: 4 }} >
              <div className="third-card-container">
                <img src={TimeImage} alt="" className="third-img" />
                <p className="third-head">
                  Only 4 hour / week
                </p>
                <p className="third-description">
                  Between classes or study breaks. add a new skill at your own pace and your own time!
                </p>
              </div>
            </Grid>
            <Grid item {...{ xs: 12, sm: 6, md: 4 }} >
              <div className="third-card-container">
                <img src={ResumeImage} alt="" className="third-img" />
                <p className="third-head">
                  Boost Your Resume
                </p>
                <p className="third-description">
                  Stand out with a top certified skill employers seek for only 3999 to 6999 INR per course.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      <Container maxWidth className=' bg-dark' >
        <div className="course-details-second-container">
          <p className="course-details-second-head">
            {course?.skills_head}
          </p>
          <p className="course-details-second-description">
            {course?.skills_description}
          </p>
        </div>
      </Container>
      <Container maxWidth>
        <div className="course-types-container">
          <p className="course-types-head">
            See What we are teaching here
          </p>
          <Grid container spacing={{ xs: 1, sm: 4 }} justifyContent='center' >
            {
              course?.courses?.map((course, index) =>
                <Grid item key={index} {...{ xs: 12, sm: 12, md: 6 }} >
                  <CourseCard course={course} />
                </Grid>
              )
            }
          </Grid>
        </div>
      </Container>
      <Container maxWidth sx={{ my: 4 }}>
        <p className="course-types-head">
          Languages & Tools Covered
        </p>
        <Grid container>
          {
            course?.tools?.map(tool => <Grid item {...{ xs: 6, sm: 6, md: 3 }}>
              <img src={tool} alt='tool' />
            </Grid>)
          }
        </Grid>
      </Container >
      <Container maxWidth >
        <div className="learn-container">
          <p className="learn-head">
            What You Will Learn
          </p>
          <p className="learn-description">
            {
              course?.what_u_learn_description
            }
          </p>
          <div className="what-u-learn">
            <Grid container spacing={5}>
              {
                course?.what_u_learn?.map((ele) =>
                  <Grid item {...{ xs: 12, sm: 6, md: 6 }}>
                    <Tag
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px"
                      }}

                      icon={<CheckCircleFilled style={{
                        fontSize: "30px"
                      }} /
                      >}
                    >{ele}</Tag>
                  </Grid>
                )
              }
            </Grid>
          </div>
        </div>
      </Container>
      <Container maxWidth sx={{
        p: 2
      }}>
        <p className="explore-head">
          Explore Our Projects
        </p>
        <p className="explore-description">
          To improve your practical exposure to the subject , we provide with you project to make you efficient in the real world technicalities of any technical domain
        </p>
        <Space size='large' style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: "center",
          flexWrap: "wrap",
          padding: "1.6rem 0"
        }} >
          {
            course?.projects?.map(pro => <Button size='large' color="#ff7722" >
              {pro}
            </Button>)
          }
        </Space>
      </Container>
      <Container maxWidth className='bg-dark' >
        <RegisterModel isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <div className="price-details-container">
          <Grid container spacing={5}>
            <Grid item {...{ xs: 12, sm: 6, md: 4 }}>
              <Card inset className="price-card-container bg-dark">
                <div className="price-head-container">
                  <p className="price-title">
                    Free
                  </p>
                  <p className="price-card-price">
                    ₹0
                  </p>
                </div>
                <List
                  itemLayout="horizontal"
                  dataSource={freeprice}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta style={{
                        color: "#fff"
                      }}
                        avatar={<CheckCircleFilled style={{
                          color: item.status ? "#ff7722" : 'gray'
                        }} />}
                        title={item.title}
                      />
                    </List.Item>
                  )}
                />
                <Button onClick={showModal}>
                  Register
                </Button>
              </Card>
            </Grid>
            <Grid item {...{ xs: 12, sm: 6, md: 4 }}>
              <Card inset className="price-card-container bg-dark">
                <div className="price-head-container">
                  <p className="price-title">
                    Self-Paced - Silver plan
                  </p>
                  <p className="price-card-price">
                    ₹3,999/-
                  </p>
                </div>
                <List
                  itemLayout="horizontal"
                  dataSource={selfprice}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta style={{
                        color: "#fff"
                      }}
                        avatar={<CheckCircleFilled style={{
                          color: item.status ? "#ff7722" : 'gray'
                        }} />}
                        title={item.title}
                      />
                    </List.Item>
                  )}
                />
                <Button onClick={showModal}>
                  Register
                </Button>
              </Card>
            </Grid>
            <Grid item {...{ xs: 12, sm: 6, md: 4 }}>
              <Card inset className="price-card-container bg-dark">
                <div className="price-head-container">
                  <p className="price-title">
                    Mentor-Led - Gold plan
                  </p>
                  <p className="price-card-price">
                    ₹6,999/-
                  </p>
                </div>
                <List
                  itemLayout="horizontal"
                  dataSource={mentorleadprice}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta style={{
                        color: "#fff"
                      }}
                        avatar={<CheckCircleFilled style={{
                          color: item.status ? "#ff7722" : 'gray'
                        }} />}
                        title={item.title}
                      />
                    </List.Item>
                  )}
                />
                <Button onClick={showModal}>
                  Register
                </Button>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth sx={{
        p: 3,
        background: "#96b3ca"
      }} >
        <Carousel autoplay afterChange={onChange}>
          {
            certificateList.map(ele => <Inside key={ele} title={ele.title} description={ele.description} imageUrl={ele.imageUrl} />)
          }
        </Carousel>
      </Container>
      <Container maxWidth sx={{
        p: 5
      }} >
        <p className="get-hired-head course-types-head">
          Students with skills getting hired in like
        </p>
        <div className="get-hired-container">
          <div className="get-hired-company">
            <img src="https://scontent.fblr5-1.fna.fbcdn.net/v/t39.8562-6/252294889_575082167077436_6034106545912333281_n.svg/meta-logo-primary_standardsize.svg?_nc_cat=1&ccb=1-7&_nc_sid=ad8a9d&_nc_ohc=0MXzGGrw-J0AX81mOSm&_nc_ht=scontent.fblr5-1.fna&oh=00_AfA_ik19qf6c9-oJMcI51vey1UsV2Tk-GuGURgpNzcp60w&oe=64626279" alt="" className="get-hired-image" />
          </div>
          <div className="get-hired-company">
            <img src={adobeSvg} alt="" className="get-hired-image" />
          </div>
          <div className="get-hired-company">
            <img src={IbmSvg} alt="" className="get-hired-image" />
          </div>
          <div className="get-hired-company">
            <img src="https://www.pwc.in/etc.clientlibs/pwc/clientlibs/rebrand-clientlibs/components-colors/resources/images/slim-header-v2/PwC-logo.svg" alt="" className="get-hired-image" />
          </div>
          <div className="get-hired-company">
            <img src={googleSvg} alt="" className="get-hired-image" />
          </div>
          <div className="get-hired-company">
            <img src='https://img.icons8.com/ios-filled/500/amazon.png' alt="" className="get-hired-image" />
          </div>
        </div>
      </Container>
    </>
  )
}

export default CourseDetails


const Inside = ({ title, description, imageUrl }) => {
  return (
    <div className="inside-container">
      <div className="inside-left">
        <p className="inside-title">
          {title}
        </p>
        <p className="inside-description">
          <ul className="inside-ul">
            {
              description.map(list => <li className="inside-list">{list}</li>)
            }
          </ul>
        </p>
      </div>
      <div className="inside-right">
        <img src={imageUrl || certificateImage} alt="" className="inside-image" />
      </div>
    </div>
  )
}

