const { createTheme } = require("@mui/material");

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#041A32"
        },
        secondary: {
            main: "#ff7722"
        }
    },
    typography: "Fira Sans",
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    position: 'sticky',
                    inset: 0,
                }
            },
            defaultProps: {
                elevation: 0
            }
        },
        MuiIconButton: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: "50%",
                    background: theme.palette.common.white,
                    boxShadow: "inset 20px 20px 60px #d9d9d9,inset - 20px - 20px 60px #ffffff"
                })
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingLeft: theme.spacing(0),
                    fontWeight: 300
                })
            }
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    background: "transparent",
                    color: "ActiveCaption",
                    border: "1px solid var(--bold_fill)"
                })
            }
        },
    }
});

export default theme