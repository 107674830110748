
import { Box, Container, Grid, Stack, styled, Typography, } from '@mui/material'
import React, { useRef } from 'react'
import { Button, Card, IconButton } from 'ui-neumorphism'
import { MailOutlined, FacebookOutlined, InstagramOutlined, WhatsAppOutlined, PhoneOutlined, WechatOutlined, LinkedinOutlined } from '@ant-design/icons'
import Lottie from 'react-lottie'
import animationData from '../../assets/contact-us.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const ContactFormHolder = styled(Box)(({ theme }) => ({
    width: '80%',
    margin: "0 auto",
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: "column",
    gridRowGap: theme.spacing(2.3),
    [theme.breakpoints.down('sm')]: {
        width: "100%"
    }
}))


const CustomTextFelid = styled('input')(({ theme }) => ({
    margin: `${theme.spacing(2)} 0`,
    // background: 'transparent',
    padding: theme.spacing(1.3),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    background: "#b3d5f0",
    boxShadow: `inset 10px 10px 20px #95b1c7,
            inset -10px -10px 20px #d1f9ff`,
    outline: '0',
    '&focus': {
        outline: "none",
        borderBottom: `1px solid ${theme.palette.primary.main}`
    },
}))


const TextArea = styled('textarea')(({ theme }) => ({
    margin: `${theme.spacing(2)} 0`,
    // background: 'transparent',
    padding: theme.spacing(2),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    background: "#b3d5f0",
    boxShadow: `inset 10px 10px 20px #95b1c7,
            inset -10px -10px 20px #d1f9ff`,
    outline: '0',
    '&focus': {
        outline: "none",
        borderBottom: `1px solid ${theme.palette.primary.main}`
    },
}))
const ContactForm = (props) => {
    const { title, leftTitle, leftDescription } = props
    const ref = useRef(null)
    return (
        <Container sx={{ py: 10 }}>
            {
                title && <Typography my={3} textAlign='center' variant='h3' fontWeight="700">
                    {title}
                </Typography>
            }
            <Grid container spacing={4} p={2} >
                <Grid item {...{ xs: 12, md: 6 }} sx={{
                    placeItems: "center"
                }} >
                    <div bordered className='contact-form' style={{
                        padding: "1rem 1.6rem"
                    }} >
                        <Typography variant='h5' fontWeight={600} my={2} >
                            {leftTitle || "In touch"}
                        </Typography>
                        <Typography color="#555"  >
                            {
                                leftDescription || "In touch with us so you can interrogate us for 24×7"
                            }
                        </Typography>
                    </div>
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                </Grid>
                <Grid item {...{ xs: 12, md: 6 }} spacing={3} >
                    <ContactFormHolder ref={ref} >
                        <Card className="contact-form" style={{
                            width: "100%",
                            padding: '2rem'
                        }} >
                            <Box display="flex" justifyContent='space-between' alignItems='center' >
                                <IconButton text={false} size='large' rounded >
                                    <FacebookOutlined style={{
                                        color: "#111"
                                    }} />
                                </IconButton>
                                <IconButton text={false} size='large' rounded >
                                    <a href="https://instagram.com/quizaroextendededge?igshid=NTc4MTIwNjQ2YQ==">
                                        <InstagramOutlined style={{
                                            color: "#111"
                                        }} />
                                    </a>
                                </IconButton>
                                <a href=" https://www.linkedin.com/company/quizaro-extendededge/">
                                    <IconButton text={false} size='large' rounded >
                                        <LinkedinOutlined style={{
                                            color: "#111"
                                        }} color='primary' />
                                    </IconButton>
                                </a>
                                <IconButton text={false} size='large' rounded >
                                    <a href="https://wa.me/qr/ZKNWI6ZI3WGTJ1">
                                        <WhatsAppOutlined style={{
                                            color: "#111"
                                        }} color='primary' />
                                    </a>
                                </IconButton>
                            </Box>
                            <Stack my={4} spacing={3} >
                                <CustomTextFelid
                                    placeholder='Email'
                                    prepend={<MailOutlined style={{
                                        fontSize: 20,
                                        color: "#ff7722"
                                    }} />}
                                    width={ref.current && ref.current.innerWidth}
                                />
                                <CustomTextFelid
                                    placeholder='Phone'
                                    prepend={<PhoneOutlined style={{
                                        fontSize: 20,
                                        color: "#ff7722"
                                    }} />}
                                    inputStyles={{

                                    }}

                                />
                                <TextArea
                                    width={ref.current && ref.current.innerWidth}
                                    prepend={<WechatOutlined style={{
                                        fontSize: 20,
                                        color: "#ff7722"
                                    }} />}
                                    rows={10} variant='standard' label="Message">
                                    Message
                                </TextArea>
                                <Button color='#111' variant='contained'>
                                    Reach US
                                </Button>
                            </Stack>
                        </Card>
                    </ContactFormHolder>
                </Grid>
            </Grid>
        </Container >
    )
}

export default ContactForm