import React from 'react'
import './hiring.css'
import { useNavigate } from 'react-router-dom'
const Hiring = ({ rtl, images, head, desc }) => {
    const navigate = useNavigate()
    return (
        <div className={` hiring`} >
            <div className="hiring-left">
                <p className="hiring-head"> {head || "Our College Collaboration"}</p>
                < p className="hiring-description">{desc || "Uniting minds for inspired learning. Join us in a synergy of knowledge and creativity that transcends traditional boundaries."}</p>
                <button onClick={() => navigate('/courses')}>Explore Opportunities</button>
            </div>
            <div className="slider">

                {
                    images.length && images?.map((ele, index) =>
                        <div className="slide" key={index.toString()}>
                            <img src={ele} alt="" width={182} height={60} />
                        </div>
                    )
                }

            </div>
        </div >
    )
}

export default Hiring