import React from 'react'
import "./courseCard.css"
import { FieldTimeOutlined, BookOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const CourseCard = ({ course, label }) => {
    const navigate = useNavigate()

    return (
        <div className='course-card' onClick={() => label && navigate(`/course-details/${label}`)} >
            <div className="course-card-image-container">
                <img className="course-card-image" src={course.image} alt='' />
            </div>
            <div className='card-content'>
                <p className="course-card-title">
                    {course.name}
                </p>
                <p className="course-details-card-description" >{course.description}</p>
                <div className="course-chips-container">
                    <div className="course-chips">
                        <FieldTimeOutlined />
                        {course?.time || '16 hours'}
                    </div>
                    <div className="course-chips">
                        <BookOutlined />
                        Curriculum
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCard