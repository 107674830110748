import { Chip, Container, FormControl, Grid, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, IconButton } from 'ui-neumorphism'
import globVideo from '../assets/glob.mp4'
import { PlayCircleOutlined } from '@ant-design/icons'
import { Steps } from 'antd'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BookFree from '../components/home/BookFree'
import RegisterModel from '../components/global/RegisterModel'
import RequestCallback from '../components/courseListing/RequestCallback'
import { query, collection, getDocs } from 'firebase/firestore'
import { db } from '../config/config'
import CourseCard from '../components/courseDetails/CourseCard'
import NoLearners from '../components/courseListing/NoLearners'
import InstructorProfile from '../components/courseListing/InstructorProfile'
import './courselist.css'
const CourseListing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoriesList, setCategoriesList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    (async () => {
      const q = query(collection(db, "Category"));
      const querySnapshot = await getDocs(q);
      const data = []
      querySnapshot.forEach((doc) => {
        data.push(doc.data())
      })
      setCategoriesList(data);
      setSelectedCategory({ label: data[0].label, title: data[0].title })
    })()
  }, [])

  const selectCat = (event) => {
    const { label, title } = event.target.value
    setSelectedCategory({ label, title })

  }

  return (


    <>
      <RequestCallback />
      <Container sx={{
        background: '#070c23'
      }} maxWidth >
        <Grid container>
          <Grid item  {...{ xs: 12, sm: 6 }} >
            <div className="course-hero-left">
              <p className="course-hero-left-heading">
                Being a student is easy. Learning requires actual work

              </p>
              <div className="course-hero-left-description">
                Quizaro learning takes place through online courses and degrees. Online classes are set up asynchronously, which means you can study at your own pace without the worry of missing any deadlines or assignments. An asynchronous environment also allows students to work on their studies whenever and wherever they want.              </div>
              <Button
                active={true}
                style={{
                  margin: "20px 0",
                  // "--bg-color": "#111936",
                  // "--light-bg-dark-shadow": "#080b18",
                  // "--light-bg-light-shadow": "#1a2754",
                }}
                color='#111'
                size='large'  >
                learn more
              </Button>
            </div>
          </Grid>
          <Grid item  {...{ xs: 12, sm: 6 }} >
            <div className="course-hero-right">
              <div className="video-container">
                <IconButton text={false} className='play-button' rounded active size='large' bgColor="#ff7722" >
                  <PlayCircleOutlined
                    style={{
                      fontSize: "30px"
                    }}
                  />
                </IconButton>
                <video autoPlay loop className="course-hero-video">
                  <source src={globVideo} type='video/mp4' />
                </video>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      
      <Container maxWidth  >
        <RegisterModel isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <div className="course-join-us">
          <p className="join-us-head">
            Join us for extended Arc 2023
          </p>
          <p className="join-us-description">
            Extended Arc is back live and in-person, and it's time to experience new moments with Quizaro. Don't miss your chance to learn and connect with the best community in edtech!
          </p>
          <Button onClick={() => showModal()} rounded bordered className='join-us-register' >
            Register now
          </Button>
        </div>
      </Container>
      <NoLearners />
      <Container maxWidth>
        <div className="course-types-container">
          <p className="course-types-head">
            Explore by our categories
          </p>
          <div className="all-categories">
            <p>Filter</p>
            <FormControl variant="filled" sx={{ m: 1, width: 300 }}>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedCategory?.label}
                variant='outlined'
                size='small'
                onChange={selectCat}
              >
                {
                  categoriesList?.map(cat =>
                    <MenuItem value={cat}>{cat?.title}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <Chip label={selectedCategory?.title} />
          </div>
          <div className="category-card-container">
            {
              <Grid container spacing={{ xs: 1, sm: 4 }} justifyContent='center' >
                {
                  categoriesList?.filter(ele => ele.label === selectedCategory.label)[0]?.courses?.map((course, index) =>
                    <Grid item key={index} {...{ xs: 12, sm: 12, md: 6 }} >
                      <CourseCard course={course} label={selectedCategory.label} />
                    </Grid>
                  )
                }
              </Grid>
            }
          </div>
        </div>
      </Container>
      <InstructorProfile />
      <Container maxWidth>
        <div className="our-boot-camp">
          <p className="our-boot-camp-sub-head">
            our online bootcamp
          </p>
          <p className="our-boot-camp-head">
            An immersive Quizaro's learning experience
          </p>
          <div className="steps">
            <Steps
              className='steps'
              direction="vertical"
              items={[
                {
                  title: 'Develop skills for real career growth',
                  description: "Avant-garde curriculum designed in counsel with pedagogic and industry to develop job-ready skills",
                  status: "finish",
                  icon: <BusinessCenterIcon sx={{
                    color: "#ff7722",
                    fontSize: "35px"
                  }} />,

                },
                {
                  title: 'Learn from experts active in their felid , not out-of touch trainers',
                  status: "finish",
                  description: "Leading practitioners who bring current Industry knowledge and case studies to sessions that fit into your work agenda.",
                  icon: <BadgeOutlinedIcon sx={{
                    color: "#ff7722",
                    fontSize: "35px"
                  }} />
                },
                {
                  title: 'Learn by working in real world problems',
                  status: "finish",
                  description: "Capstone projects involving real world metadata with virtual labs for hands-on learning",
                  icon: <SyncProblemOutlinedIcon sx={{
                    color: "#ff7722",
                    fontSize: "35px"
                  }} />
                },
                {
                  title: 'structured guiding ensuring learning never stops',
                  status: "finish",
                  description: "Around-the-clock Learning support from mentors and a community of like-minded peers to resolve any conceptual doubts",
                  icon: <AccessTimeOutlinedIcon sx={{
                    color: "#ff7722",
                    fontSize: "35px"
                  }} />
                },
              ]}
            />
          </div>
        </div>
      </Container>
      <BookFree inset={true} />
      <div className='bg-dark find-container'>
        <div className="find-course">
          <div className="find-info">
            <p className="find-let-us">
              Let us help
            </p>
            <p className="find">
              Finding right course
            </p>
          </div>
          <Button onClick={() => showModal()} active color='#fff' bgColor="#ff7722" className='dark-utils' >
            Get Started
          </Button>
        </div>
      </div>
    </>
  )
}

export default CourseListing