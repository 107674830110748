import React from 'react'
import './Termsandconditions.css'
import { Box, Container } from '@mui/material'
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { IconButton } from 'ui-neumorphism'
const TermsAndConditions = () => {
  return (
    <Container maxWidth >
      <div className="support">
        <Box display="inline-flex" mx='auto' className='support-social' justifyContent='space-between' alignItems='center'  >
          <IconButton className='util' text={false} size='large' rounded >
            <FacebookOutlined style={{
              color: "#111"
            }} />
          </IconButton>
          <IconButton className='util' text={false} size='large' rounded >
            <a href="https://instagram.com/quizaroextendededge?igshid=NTc4MTIwNjQ2YQ==">
              <InstagramOutlined style={{
                color: "#111"
              }} />
            </a>
          </IconButton>
          <a href=" https://www.linkedin.com/company/quizaro-extendededge/">
            <IconButton className='util' text={false} size='large' rounded >
              <LinkedinOutlined style={{
                color: "#111"
              }} color='primary' />
            </IconButton>
          </a>
          <IconButton className='util' text={false} size='large' rounded >
            <a href="https://wa.me/qr/ZKNWI6ZI3WGTJ1">
              <WhatsAppOutlined style={{
                color: "#111"
              }} color='primary' />
            </a>
          </IconButton>
        </Box>
        <div className='contact-links' >
          <MailOutlined style={{
            color: "#fff",
            margin: "0 5px"
          }} />
          Support@quizaro.in
        </div>
        <div className='contact-links'>
          <PhoneOutlined style={{
            color: "#fff",
            margin: "0 5px"

          }} />
          9380036855
        </div>
      </div>
      <div className='terms-and-condition'>
          
        <h3 className='main-heading'>
          Terms & Conditions
        </h3>
        <p>
          This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy and Terms of Use for access or usage of the www.quizaro.in
          QUIZARO EXTENDEDEDGE PRIVATE LIMITED provides this website to you under the following terms and conditions (this "Agreement" or "Terms of Use"). Our Privacy Policy (as in posted to our website), which explains how we collect and use information from visitors to our website is incorporated by reference in these Terms of Use. By using our website (the "Site") or submitting any information to us, you consent to and agree to be bound by these Terms of Use and our Privacy Policy. By using the Site in any manner, including but not limited to visiting or browsing the Site, you agree to be bound by this Agreement, the Privacy Policy, and all other operating rules, policies and procedures that may be published by us from time to time on the Site, each of which is incorporated by reference and each of which may be updated by us from time to time without notice to you. Your use of the Site is governed by the version of this Agreement in effect on the date of use
        </p>
        <h4 className="second-heading">
          Account Registration
        </h4>
        <p>
          If you are under the age of 18, please do not sign up for an account. If you are accessing or using our site on behalf of any entity, you represent that you are authorized to accept these Terms of Use on that entity's behalf, that entity agrees to be responsible to QUIZARO if you violate these Terms of Use.
          You may access some areas of our site(s) without registering. However, in order to access certain features of the Site or to post content on the Site you must register to create an account ("Account"). You must complete the registration process by providing us with current, complete and accurate information. If you choose to register you will need to provide a username and password as well as other information that is part of our registration process. You will not share your password, let anyone else access your Account, or do anything else that might jeopardize the security of your Account. We have the right to cancel any account at any time if we believe you are not in compliance with any or all of the terms.
        </p>
        <h4 className="second-heading">
          Courses & Offered Payment
        </h4>
        <p>
          Information about courses offered and prices would be provided in the Website. You agree to pay the price for the courses registered as per the pricing schedule indicated in the Site. At the time of a payment, you need to provide accurate information regarding your account, including a valid credit card or debit card or net banking credentials, and you must certify that you are over 18 years of age and legally able to enter into a valid and binding agreement with QUIZARO
        </p>
        <h4 className="second-heading">
          Processing & Account Payment

        </h4>
        <p>
          QUIZARO may use third party electronic payment processors and/or financial institutions ("EPPs") to process financial transactions. You irrevocably authorize us, as necessary, to instruct such EPPs to handle such transactions and you irrevocably agree that QUIZARO may give such instructions on your behalf in accordance with your requests as submitted on the Site.
          While availing any of the EPPs available on the Site, We will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:
          Lack of authorization for any transactions.
          Any payment issues arising out of the transaction or
          Decline of such transaction for any reason.
          You understand, accept and agree that the payment facility provided us, is neither a banking nor financial service.
          Modification of Pricing And Billing Terms-
          We reserve the right at any time to institute new prices, and to change the amount of or the basis for determining any prices or charges with respect to any courses offered. You agree to pay all charges, including applicable taxes, in accordance with the billing terms in effect at the time the price or charge becomes payable.
        </p>
        <h4 className="second-heading">
          INTELLECTUAL PROPERTY
        </h4>
        <p>
          Quizaro expressly reserves all intellectual property rights in all text, programs, products, processes, technology, content, software and other materials, which appear on this Website. The compilation (meaning the collection, arrangement and assembly) of the content on the Website is the exclusive property of Quizaro. Reproduction in whole or in part of the same is strictly prohibited without the express written permission of Quizaro.
          The trademarks, logo and slogans displayed on the Site including the mark Quizaro, (collectively the "Trademarks'') are owned by Quizaro. Nothing on this Site should be construed as granting any license or right to use the Trademarks without written permission of Quizaro or such third party owner of the Trademarks. Your misuse of the Trademarks, or any other content on the Site, except as provided in these Terms of Use, is strictly prohibited. You are advised that QUIZARO will aggressively enforce its intellectual property rights to the fullest extent of the law.
          Quizaro grants to the registered users a non-exclusive, non-transferable, revocable right to use the contents on the Site for non-profit, educational and research use only, without the right of sublicense. Quizaro respects the intellectual property of others, and we ask our Users and content partners to do the same. The unauthorized posting, reproduction, copying, distribution, modification, public display or public performance of copyrighted works constitutes infringement of the copyright owner’s rights. As a condition of your use of the Site, you agree not to use any feature of the Site to infringe the intellectual property rights of others in any way. We will terminate the accounts of any User, and block access to Site of any User who are repeat infringers of the copyrights, or other intellectual property rights, of QUIZARO or others. We reserve the right to take these actions at any time, in our sole discretion, with or without notice, and without any liability to the User who is terminated or to the User whose access is blocked.
          Intellectual Property Right” for the purpose of the Terms of Use, shall mean all copyrights (including without limitation the exclusive right to reproduce, distribute copies of, display and perform the copyrighted work and to prepare derivative works), copyright registrations and applications, trademark rights (including, without limitation, registrations and applications), patent rights (whether registered or unregistered), trade names or business names, registered and unregistered design rights, mask-work rights, trade secrets, moral rights, author’s rights, rights in packaging, goodwill and other intellectual property rights, and all renewals and extension thereof and all rights or forms of protection of a similar nature or having an equivalent or similar effect to any of the above, which may subsist anywhere in the world, regardless of whether any of such rights arise under the laws of India or any other state, country or jurisdiction

        </p>
        <h4 className="second-heading">
          DATA PROTECTION/PRIVACY
        </h4>
        <p>
          We may change this Policy at any time, in particular as needed to comply with the rules and regulations of the various governmental and regulatory organizations, or to comply with applicable laws and regulations. The revised version will be effective at the time we post it on the website or inform the User. If a User uses the Services or accesses the website after a notice of changes has been sent to such User or published on the Website, such User hereby provides his/her/its consent to the changed terms.
        </p>
        <h4 className="second-heading">

          RESTRICTED ACTIONS
        </h4>
        <p>

          During the use of the Site, you shall not:
          Attempt to copy, modify, duplicate, imitate, reproduce, create derivative works from, frame, mirror, or download, all or any portion of the Site in any form or media or by any means.
          Attempt to decompile, disassemble, reverse engineer or otherwise attempt to discover any source code from, all or any part of the site.
          Sell, resell, redistribute or otherwise commercially exploit any material from this Site except for content specifically and expressly made available for redistribution.
          Access or use all or any part of the Site in order to build or create a product or service which is similar to, or which competes with, the business of Quizaro.
          Attempt to obtain, or assist third parties in obtaining, unauthorized access to the site.
          Abuse the use of the site in any way including for advertising or solicitation to buy or sell any products and/or for creating, sharing and sending unsolicited commercial messages, bulk email, "junk mail", "spam" or chain letters.
          Create or send any viruses, worms or trojan horses, flood or mail bombs, or engage in denial of service attacks while using the Site.
          Use the Site in any manner that damages, disables, overburdens, or impairs the Site, or Quizaro's systems or servers, or the cloud or other platform on which the we operate, and/or otherwise interferes with any other party's use and enjoyment of the Site.
          Access the site by any means other than through the interface that is provided by us for accessing the Site.
          Permit any third party to do any of the foregoing.
          You shall use all reasonable endeavors to prevent any unauthorized access to, or use of, the Site and, in the event of any such unauthorized access or use, it shall promptly notify us.
        </p>
        <h4 className="second-heading">

          THIRD PARTY CONTENT
        </h4>
        <p>

          The Site may provide links to or frame third-party websites or resources and may link User automatically to sponsors’ or third party’s websites or resources. You acknowledge and agree that Quizaro is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Quizaro of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources.You should review the applicable third party’s terms and policies, including privacy and data gathering practices of any website to which you navigate.
        </p>
        <h4 className="second-heading">
          NOTIFICATIONS
        </h4>
        <p>
          You agree to Quizaro sending you notifications and important messages from time to time via its websites, mobile applications, and email to provide you a better experience with the services provided by us. You agree that we may provide notices to you on our website, or through an email sent to an email address you provided, or through other means including but not limited to your mobile number, telephone, or email. You agree to keep your contact information up to date.
        </p>
        <h4 className="second-heading">
          GRIEVANCE OFFICER
        </h4>
        <p>
          In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
          <ul style={{
            padding: "1rem"
          }} >
            <li>
              Name: Srinivas M R
            </li>
            <li>Designation: Business Operations and Administration</li>
            <li>Phone: 9380036855</li>
            <li> Email: srinivas@quizaro.in</li>
            <li>Time: 11:00 AM - 09:00 PM</li>
          </ul>
        </p>
        <h4 className="second-heading">

          NO REPRESENTATIONS OR WARRANTIES
        </h4>
        <p>
          Your access to the site is at your sole risk. Our services are provided on “AS IS” and “AS AVAILABLE” basis without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. Quizaro makes no representation as to the completeness or accuracy of the information provided on the Site. Quizaro does not endorse or represent the reliability or accuracy of any content or information distributed through or accessed from the Site, and has not performed any investigation into such information.
          To the extent provided by law, Quizaro assumes no liability or responsibility for any errors or omissions in the content of the Site. Quizaro has not reviewed all of the links provided on the Site and is not responsible for the content of any off-Site pages. Clicking on hyperlinks and visiting any off-Site pages is done at your own risk.
        </p>
        <h4 className="second-heading">

          CHANGE OR TERMINATION-
        </h4>
        <p>
          We may, without prior notice, change the Site, stop providing the Site, applications or services, or create usage limits for the Site. We may permanently or temporarily terminate or suspend your access to the Site without notice or liability, for any reason or for no reason, including if in our sole determination you violate any provision of these Terms of Use. Upon termination of these Terms of Use or your access to the Site for any reason or no reason, you will continue to be bound by these Terms of Use which, by their nature, should survive termination, including without limitation ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </p>
        <h4 className="second-heading">
          LIMITATION OF LIABILITY
        </h4>
        <p>
          To The Fullest Extent Permitted By Applicable Law, Neither Quizaro Nor Its Affiliates Will Be Liable Under Any Theory Of Liability (Whether In Contract, Tort, Negligence, Or Otherwise) For Any Indirect, Incidental, Special, Consequential, Punitive, Or Exemplary Damages, Including, Damages For Loss Of Revenues, Profits, Goodwill, Use, Data, Or Other Intangible Losses (Even If Such Parties Were Advised Of, Knew Of, Or Should Have Known Of The Possibility Of Such Damages), Arising From Or Relating To The Sites Or Services Or These Terms Of Use.
        </p>
        <h4 className="second-heading">
          INDEMNIFICATION
        </h4>
        <p>
          User agrees to indemnify, defend and hold harmless Quizaro and its Affiliates from and against any and all claims, liabilities, damages, losses, costs, expenses, and fees of any kind (including reasonable attorneys’ fees and legal costs), arising from or relating to: (a) any information (including User Content or any other content) that User or anyone using User’s account submits, posts, or transmits on or through the Sites or Services; (b) the use of the Sites or Services by User or anyone using User’s account; (c) the violation of these Terms of Use by User or anyone using User’s account; or (d) the violation of any rights of any third party, including intellectual property, privacy, publicity, or other proprietary rights by User or anyone using User’s account.
        </p>
        <h4 className="second-heading">
          JURISDICTION
        </h4>
        <p>  The Terms of Use shall be governed by the laws of India, and the courts at Bengaluru, India shall have exclusive jurisdiction with respect to any dispute arising hereunder.</p>
        <h4 className="second-heading">
          INTERNATIONAL USE
        </h4>
        <p>The Site is controlled and operated from within India. Quizaro makes no representation that the Site is appropriate or available in locations outside of India. Those who choose to access the Site or use our services from other locations do so at their own risk and are responsible for compliance with applicable laws.</p>
        <h4 className="second-heading">
          GENERAL
        </h4>
        <p>The failure of Quizaro to enforce any right or provision of these Terms of Use will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Quizaro. Except as expressly set forth in these Terms of Use, the exercise by either party of any of its remedies under these Terms of Use will be without prejudice to its other remedies under these Terms of Use or otherwise. If for any reason a court of competent jurisdiction, as applicable, of competent jurisdiction finds any provision of these Terms of Use invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms of Use will remain in full force and effect.</p>
        <h4 className="second-heading">
          MISCELLANEOUS
        </h4>
        <p>Except as expressly provided in these Terms of Use, there shall be no third-party beneficiaries to the Terms of Use. Quizaro shall have the right to assign its rights or delegate any of its responsibilities under these Terms of Use to an affiliate or in connection with a merger, consolidation or reorganization of Quizaro for the sale of substantially all of its assets.</p>
        <p> In the event that any provision of this Agreement, or the application thereof, becomes or is declared by a court of competent jurisdiction to be illegal, void or unenforceable, the remainder of this Agreement will continue in full force and effect and the application of such provision to other persons or circumstances will be interpreted so as reasonably to effect the intent of the parties hereto.
          This Agreement constitutes the entire agreement between Quizaro and User regarding the subject matter hereof, and supersede any previous arrangement, understanding or agreement between the parties, written or oral, relating to the subject matter hereof.
        </p>
        <p> Notwithstanding anything to the contrary herein, Quizaro will not be held liable for any delay or failure in performance or non-availability of the services resulting, directly or indirectly, from acts of nature, events, omissions, accidents or causes beyond its reasonable control, including, but not limited to, internet failure, network or computer equipment failures, telecommunication equipment failure, electrical power failures, strikes, lock-outs or other industrial disputes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, breakdown of plant or machinery, acts of God, war, governmental actions or orders, orders of domestic or foreign courts or tribunals.</p>
        <h3 className="heading">
          ONLINE INTERNSHIP
        </h3>
        <h4 className="second-heading">
          SCOPE
        </h4>
        <p>

          "The fees that we charge the students is only for training them via live classes and not internship itself. Quizaro does not charge any amount for the internship project phase as it is completely complimentary."

        </p>
        <h3 className="heading">OFFLINE INTERNSHIP
        </h3>
        <h4 className="second-heading">
          SCOPE
        </h4>
        <p>

          "The fees that we charge the students is for the offline workshops that are conducted and not for the online project phase. The online project phase would be complimentary for all the participants in the offline workshop."
        </p>
        <h4 className="second-heading">
          PURPOSE
        </h4>
        <p>

          This policy governs the refunds process and will address matters arising out of situations that may require processing of refunds.
        </p>
        <h4 className="second-heading">

          SCOPE
        </h4>
        <p>
          The policy encompasses employees, students, and candidates that are in the ecosystem of requesting and/or processing refunds.
          This policy does not cover situations where refunds are not approved.
          There are two components to internship, certification and job guarantee program fees:
          Registration Charges.
          Course Fees.
        </p>
        <h4 className="second-heading">
          procedure
        </h4>
        <h3 className="heading">
          Internship Programs
        </h3>
        <h4 className="second-heading">
          Self Paced Internship Program
        </h4>
        <p>
          All self paced internship programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the complete programOnline Internship Program:All online internship programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the complete program
        </p>
        <h4 className="second-heading">
          Offline Internship Program:

        </h4>
        <h4 className="second-heading">
          Hybrid Internship Program:
        </h4>
        <p>
          All offline and Hybrid internship programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the complete program
        </p>
        <h3 className="heading">
          Certification Programs
        </h3>
        <h4 className="second-heading">
          Self Paced Certification Programs:
        </h4>
        <p>
          Self Paced certification programs are refundable only if refund request is raised up to 1 week before the date of commencement and the student has not accessed the LMS. Any refund requests post LMS access will be strictly denied. Please note that the registration charges which are 20% of the course fee or Rs.1000/- (whichever is higher) are non refundable at any point in time.
        </p>

        <h4 className="second-heading">
          Online Certification Program
        </h4>
        <p>
          All online certification programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the entire program
        </p>
        <h4 className="second-heading">
          Offline Certification Program
        </h4>
        <p>
          Offline certification programs are refundable only if a refund request is raised 1 week prior to the commencement of classes and the student has not accessed the LMS. Please note that the registration charges which are 20% of the course fee or Rs.1000/- (whichever is higher) are non refundable at any point in time.
        </p>
        <h4 className="second-heading">
          Hybrid Certification Program
        </h4>
        <p>
          Hybrid certification programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the entire program.
          Job Guarantee Programs-Self Paced Job Guarantee (Assistance) Program:
          Self paced programs are refundable only if refund request is raised up to 1 week before the date of commencement and the student has not accessed the LMS. Any refund requests post LMS access will be strictly denied. Please note that the registration charges which are 20% of the course fee or Rs.1000/- (whichever is higher) are non refundable at any point in time.
        </p>
        <h4 className="second-heading">
          Online Guarantee Program:
        </h4>
        <p>
          All online job guarantee programs are non-refundable and non-transferrable under any circumstances. The refunds will happen only if student has successfully completed the course but is unable to secure a job provided:85% attendance for the classes held.All major projects, minor projects and case studies have been successfully completed.Exams or certification requirements for the course have been met.Full refunds will be issued only if Quizaro cancels the entire program.
        </p>
        <h4 className="second-heading">
          Hybrid Certification Program:
        </h4>
        <p>
          Hybrid certification programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the entire program.

        </p>
        <h3 className="heading">
          Job Guarantee Programs
        </h3>
        <h4 className="second-heading">
          Self Paced Job Guarantee (Assistance) Program:

        </h4>
        <p>
          Self paced programs are refundable only if refund request is raised up to 1 week before the date of commencement and the student has not accessed the LMS. Any refund requests post LMS access will be strictly denied. Please note that the registration charges which are 20% of the course fee or Rs.1000/- (whichever is higher) are non refundable at any point in time.
        </p>
        <h4 className="second-heading">
          Online Guarantee Program:
        </h4>
        <p>
          All online job guarantee programs are non-refundable and non-transferrable under any circumstances. The refunds will happen only if student has successfully completed the course but is unable to secure a job provided:
          85% attendance for the classes held.
          All major projects, minor projects and case studies have been successfully completed.
          Exams or certification requirements for the course have been met.
          Full refunds will be issued only if Quizaro cancels the entire program.

        </p>
        <h4 className="second-heading">
          OFFLINE CERTIFICATION PROGRAM
        </h4>
        <p>
          Offline certification programs are refundable only if a refund request is raised 1 week prior to the commencement of classes and the student has not accessed the LMS. Please note that the registration charges which is 20% of the course fee or Rs.1000/- (whichever is higher) are non refundable at any point in time
        </p>
        <h4 className="second-heading">
          Hybrid Certification Program:
        </h4>
        <p>
          Hybrid certification programs are non-refundable and non-transferrable under any circumstances. Full refunds will be issued only if Quizaro cancels the entire program.

        </p>
        <h4 className="second-heading">
          Process for Refund
        </h4>
        <p>
          For all refunds please contact us at: support@quizaro.in
          The refunds team will contact you within 2-5 business days.
          For approved refund cases Refunds Form need to be filled.
          In 7-10 business days a refund is issued.

        </p>
        <h4 className="second-heading">
          How Your Refund is Returned
        </h4>
        <p>
          If your payment came from your card, it will be refunded to that card. Depending on your card issuer, it may take up to 30 days to complete a refund.
          If your payment came from your bank account, it will be refunded to that bank account.
          If your payment came from some other source (instamojo, phonepe, google play etc) it will be refunded back to the same source.
          Please note no cash refunds are made under any circumstances.
        </p>

        <h4 className="second-heading">
          CHANGE OF COURSE
        </h4>
        <p>
          Quizaro allows you to change your course to an alternative course only once using the following guidelines:
          Quizaro is notified in writing at least 1 week before the commencement of the original course. The alternative course has seats available and will be finished within 1 month of the original course finish date. If switching to a course of a higher value, students are required to pay the amount difference. However, if switching to a course of a lower value, Quizaro is not liable to pay the amount difference. A processing fee of Rs. 500/- needs to be paid within 3 days after Quizaro accepts change of course request, failing which course change request will be canceled and no further request will be entertained.
          Course change is allowed only once during the program.

        </p>
        <h4 className="second-heading">
          RESCHEDULE
        </h4>
        <p>
          <b>Rescheduling by Quizaro ExtendedEdge Edtech are governed by the following guidelines</b>
          <br />
          If a course doesn’t begin within 10 business days of published commencement date, a student is entitled to either a full refund or can choose to join a different batch/course.
          Quizaro will make all possible efforts to conduct a course. However, in the event of a course being rescheduled due to unforeseen delays on our end, Quizaro EduTech is only responsible for the cost of the course. Any travel, logistics or personal expenses incurred due to this program won’t be refunded.

          <b> Rescheduling request by customer are governed by the following guidelines:</b> <br />
          The reschedule request has to be made at least 7 business days before the commencement of the course.
          The rescheduled date should be within 90 days of the initial commencement date.
          Rescheduling will not allow the customer to change their course.
          The reschedule is subject to availability of seats.
          A rescheduling fee of Rs. 500/- must be paid within 5 business days from the date of approval.

          <br /> <b><i>Process for Rescheduling of Course</i></b><br />
          Contact Quizaro at support@quizaro.in with your course rescheduling request.
          Once approved, fill up the “Reschedule Course Form” and submit it within 3 business days.

          Pay the processing fees + difference in course costs (if applicable) and submit the receipt to “support@quizaro.in” In 5-7 business days you will get a confirmation for the course reschedule request.
          If a rescheduled course is of a higher value, students are required to pay the amount difference. However, if switching to a course of a lower value, Quizaro is not liable to pay the amount difference. A processing fee of Rs. 500/- needs to be paid within 3 days after Quizaro accepts reschedule request, failing which reschedule request will be canceled and no further request will be entertained.

        </p>
        <h4 className="second-heading">
          CANCELLATION
        </h4>
        <p>
          Quizaro ExtendedEdge Edtech reserves the right to cancel any session or change the location of the session for reasons such as Instructor unavailability, insufficient participants or force majeure events such as earthquakes, fire, flood, riots, curfew, bandh, protest, strike, civil unrest etc.
          <br />
          <b>Please note our cancellation policy is governed by the following:</b> <br />
          <p>
            In case Quizaro ExtendedEdge Edutech cancels workshop/seminar/conference, 100% refund will be paid to the participants.
          </p>
          <p>
            In case Quizaro ExtendedEdge Edutech changes the location of the event, arrangements of a similar infrastructure as previously decided will be executed.
          </p>
          <p>
            No personal expenses due to the change of location will be borne by Quizaro Edutech under any circumstances. Quizaro Edutech reserves the right to either accommodate students in a similar infrastructure as originally decided or pay compensation Quizaro Edutech deems fit for the same.
          </p>

        </p>
      </div>
    </Container>
  )
}

export default TermsAndConditions