import React from 'react'
import './InstructorProfile.css'
import { Container } from '@mui/material'

const InstructorProfile = () => {
    return (
        <Container maxWidth>
            <div className="instructor-container">
                <p className="inst-heading">
                    Instructor Profile
                </p>
                <p className="inst-desc">
                    Seasoned professional, real-world insights. Industry expertise meets passionate teaching. Learn from the best to excel in your journey.
                </p>
                <div className="inst-info-container">
                    <div className="inst-info-div">
                        <p className="inst-info-title">No.1 Industry Experts.</p>
                        <p className="inst-info-desc">
                            Learn from the pinnacle of industry knowledge. Acquire insights that drive excellence. Your path to mastery starts here.
                        </p>
                    </div>
                    <div className="inst-info-div">
                        <p className="inst-info-title">Years of experience
                        </p>
                        <p className="inst-info-desc">
                            Decades of Experience. Benefit from years of seasoned expertise. Elevate your learning with insights honed over time.
                        </p>
                    </div>
                    <div className="inst-info-div">
                        <p className="inst-info-title">Areas of Expertise
                        </p>
                        <p className="inst-info-desc">
                            Expertise in Diverse Areas. Mastery across various domains. Gain comprehensive insights and skills from a versatile instructor.
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default InstructorProfile