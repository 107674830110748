import { MobileOutlined, RobotOutlined, GlobalOutlined, DotChartOutlined, SearchOutlined } from '@ant-design/icons'
import { Container, Typography } from '@mui/material'
import React from 'react'
import { Card } from 'ui-neumorphism'



const OurServices = () => {
    return (
        <Container maxWidth sx={{ py: 3 }} >
            <Typography variant='h5' py={4} textAlign='center' textTransform="uppercase" >
                Services
            </Typography>
            <div className="our-services-right">
                <div className="our-services-cards">
                    <Card style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "grid",
                        placeItems: "center",
                        // "--bg-color": "#111936",
                        // "--light-bg-dark-shadow": "#080b18",
                        // "--light-bg-light-shadow": "#1a2754",
                    }} >
                        <GlobalOutlined
                            style={{
                                color: "#ff7722",
                                fontSize: "32px"
                            }}
                        />

                    </Card>
                    <p className="our-services-cards-text">
                        Web Development
                    </p>
                    <p className="our-services-cards-description">
                        Quizaro have experts in web development to Career growth and Freelance opportunities, we will help you to build Ability to work in various work environments and create Opportunity for innovation and invention with Chance to learn transferable skills up to Extended Edge.                    </p>
                </div>
                <div className="our-services-cards">
                    <Card style={{
                        // "--bg-color": "#111936",
                        // "--light-bg-dark-shadow": "#080b18",
                        // "--light-bg-light-shadow": "#1a2754",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "grid",
                        placeItems: "center"
                    }} >
                        <MobileOutlined
                            style={{
                                color: "#ff7722",
                                fontSize: "32px"
                            }}
                        />

                    </Card>
                    <p className="our-services-cards-text">
                        App Development
                    </p>
                    <p className="our-services-cards-description">
                        Android Development is not only an easy skill to learn, but Quizaro make it easy up to Extended Edge. By learning Android Development, you give yourself the best possible chance to reach any career goals you set. Once you get started, within no time, you’ll land your dream job, have that promotion, or create a successful business of your own in the field of Android Development.
                    </p>
                </div>
                <div className="our-services-cards">
                    <Card style={{
                        // "--bg-color": "#111936",
                        // "--light-bg-dark-shadow": "#080b18",
                        // "--light-bg-light-shadow": "#1a2754",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "grid",
                        placeItems: "center"
                    }} >
                        <RobotOutlined
                            style={{
                                color: "#ff7722",
                                fontSize: "32px"
                            }}
                        />

                    </Card>
                    <p className="our-services-cards-text">
                        Automation
                    </p>
                    <p className="our-services-cards-description">
                        E learning automation is the automation of tasks associated with Quizaro online learning. These include, but are not limited to Grading and feedback, Peer assessment and communication Administration and faculty support, online learning activities                    </p>
                </div>
                <div className="our-services-cards">
                    <Card style={{
                        // "--bg-color": "#111936",
                        // "--light-bg-dark-shadow": "#080b18",
                        // "--light-bg-light-shadow": "#1a2754",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "grid",
                        placeItems: "center"
                    }} >
                        <DotChartOutlined
                            style={{
                                color: "#ff7722",
                                fontSize: "32px"
                            }}
                        />

                    </Card>
                    <p className="our-services-cards-text">
                        Machine Learning
                    </p>
                    <p className="our-services-cards-description">
                        Learning machine learning with quizaro enhances your career trajectory and learning this skills can improve cyber security knowledge and protect against financial fraud by learning machine learning, you can help save lives an understanding of machine learning is essential to remain technologically up to date.                    </p>
                </div>
                <div className="our-services-cards">
                    <Card style={{
                        // "--bg-color": "#111936",
                        // "--light-bg-dark-shadow": "#080b18",
                        // "--light-bg-light-shadow": "#1a2754",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "grid",
                        placeItems: "center"
                    }} >
                        <SearchOutlined
                            style={{
                                color: "#ff7722",
                                fontSize: "32px"
                            }}
                        />

                    </Card>
                    <p className="our-services-cards-text">
                        Digital Marketing
                    </p>
                    <p className="our-services-cards-description">
                        Digital Marketing with Quizaro create very good career, and it's in high demand & a good-paying job in the current world. Digital marketing course gives benefits such as increased brand loyalty, Cost-effective marketing, High returns on investment, audience targeting option, high conversion rates, coverage to worldwide media, brand awareness through social media, and improved relation between customer and seller.                    </p>
                </div>
            </div>
        </Container>
    )
}

export default OurServices