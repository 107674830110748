import { Container, Typography } from '@mui/material'
import ContactForm from '../components/global/ContactForm'
import OurPartners from '../components/home/OurPartners'
import WeDo from '../components/home/WeDo'


const About = () => {
  return (
    <main>
      <section className="header" >
        <div className="hero">
          <div className="heading">
            <h3>
              What we are
            </h3>
            <p>
              we teach better
            </p>
          </div>
        </div>
      </section>
      <section className='bg-dark' style={{
        padding: "0.9rem"
      }} >
        <Typography variant='h4' fontWeight="700" textTransform="uppercase" textAlign="center">
          We<Typography component='span' className='colorText' variant='h4' fontWeight="700" textTransform="uppercase" > Aim</Typography> At
        </Typography>
        <Container>
          <Typography >
            Quizaro offers streamlined learning solution focused on delivering the best-in-class content, faculty, and pedagogy to aspirants, while balancing affordability and industry-relevant learning outcomes for individual learners. education offerings by adding specialized professional certifications to help learners up-skill with industry-relevant curricula, get noticed, and become future-proof in the dynamic
            business world. The joy of learning is as indispensable in study as breathing is to running.
          </Typography>
        </Container>
      </section>
      <Container>
        <WeDo />
      </Container>
      <OurPartners />
      <section className='contactForm'>
        <ContactForm title="Let's Learn  Together" />
      </section>
    </main>
  )
}

export default About