import React from 'react'
import './success.css'
import MainHeader from '../components/success/MainHeader'
import { Grid } from '@mui/material'
import semiCircle from '../assets/images/semicircle.svg'
import halfCircle from '../assets/images/halfCircle.svg'
import star from '../assets/images/star.svg'
const SuccessStories = () => {
    return (
        <main className='success-stories-main'>
            <MainHeader />
            <section className='success-about-company'>
                <div className="success-right">
                    <p className="success-head">
                        Quizro: The Evolutionary Game
                    </p>
                </div>
                <div className="success-left">
                    <p>
                        Our streamlined learning solutions will supercharge your education by delivering top-notch content, exceptional instructors, and innovative pedagogy at an affordable price. Quizaro is everything you’ve been looking for—and more!
                    </p>
                </div>
            </section>
            <section className="success-achievements">
                <Grid container spacing={4} >
                    <Grid item {...{ xs: 12, md: 4 }}>
                        <div className="achievements-card">
                            <img src={semiCircle} alt="semi circle" className="success-image" />
                            <p className="success-number">
                                1000+
                            </p>
                            <p className="success-desc">
                                Success Stories
                            </p>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }}>
                        <div className="achievements-card">
                            <img src={halfCircle} alt="semi circle" className="success-image" />
                            <p className="success-number">
                                300+
                            </p>
                            <p className="success-desc">
                                Industry Experts </p>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }}>
                        <div className="achievements-card">
                            <img src={star} alt="semi circle" className="success-image" />
                            <p className="success-number">
                                50+
                            </p>
                            <p className="success-desc">
                                Courses
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </section>
            <section className="success-achievements">
                <Grid container spacing={5} >
                    <Grid item {...{ xs: 12, md: 4 }}>
                        <div className="success-review-card achievements-card">
                            <div className="user-profile">
                                <img src="https://api.dicebear.com/6.x/adventurer-neutral/svg?seed=Tigger" alt="" className="user-image" />

                            </div>
                            <p className="user-review">
                                Quizaro made my studies exciting and engaging.
                            </p>
                            <p className="user-name">
                                Kishan Kumar
                            </p>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }}>
                        <div className="success-review-card achievements-card">
                            <div className="user-profile">
                                <img src="https://api.dicebear.com/6.x/fun-emoji/svg?seed=Snowball" alt="" className="user-image" />

                            </div>
                            <p className="user-review">
                                Quizaro made my studies exciting and engaging.
                            </p>
                            <p className="user-name">
                                Kishan Kumar
                            </p>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }}>
                        <div className="success-review-card achievements-card">
                            <div className="user-profile">
                                <img src="https://api.dicebear.com/6.x/adventurer/svg?seed=Cuddles" alt="" className="user-image" />

                            </div>
                            <p className="user-review">
                                Quizaro made my studies exciting and engaging.
                            </p>
                            <p className="user-name">
                                Kishan Kumar
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </section>
            <section className="success-achievements" style={{
                marginBottom: 0
            }} >
                <Grid container spacing={5} >
                    <Grid item {...{ xs: 12, md: 4, }} >
                        <div className="user-experience-card">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Fmaxim-hopman-8vn4KvfU640-unsplash.jpg?alt=media&token=68c93959-6f80-438e-900b-2d89967b6d23" alt="" className="user-exp-image" />
                            <div className="user-exp-info">
                                <p className="user-exp-head">
                                    Immersive Experiences
                                </p>
                                <p className="user-exp-desc">
                                    Dive into a world of unlimited possibilities
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }} >
                        <div className="user-experience-card">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Fbrooke-cagle-g1Kr4Ozfoac-unsplash.jpg?alt=media&token=618b3b91-86a6-457d-8bee-6a26f3f61bd8" alt="" className="user-exp-image" />
                            <div className="user-exp-info">
                                <p className="user-exp-head">
                                    Collaborative Learning
                                </p>
                                <p className="user-exp-desc">
                                    Become a better learner alongside your peers
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }} >
                        <div className="user-experience-card">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Festee-janssens-zEqkUMiMxMI-unsplash.jpg?alt=media&token=d1024060-24b1-43dd-b263-580a21a2d2e3" alt="" className="user-exp-image" />
                            <div className="user-exp-info">
                                <p className="user-exp-head">
                                    Achieve Your Goals
                                </p>
                                <p className="user-exp-desc">
                                    Give your dreams wings to soar
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }} >
                        <div className="user-experience-card">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Fintenza-fitness-ybL6getznP8-unsplash.jpg?alt=media&token=6e03e01a-41f2-440e-afb6-61d3d798e796" alt="" className="user-exp-image" />
                            <div className="user-exp-info">
                                <p className="user-exp-head">
                                    Flexible and Convenient
                                </p>
                                <p className="user-exp-desc">
                                    Learn anytime, anywhere
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }} >
                        <div className="user-experience-card">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Fcaspar-camille-rubin-89xuP-XmyrA-unsplash.jpg?alt=media&token=78dc91ec-4f47-4113-8c9d-73eb89599b71" alt="" className="user-exp-image" />
                            <div className="user-exp-info">
                                <p className="user-exp-head">
                                    Innovative Pedagogy
                                </p>
                                <p className="user-exp-desc">
                                    Stay ahead of the curve
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item {...{ xs: 12, md: 4 }} >
                        <div className="user-experience-card">
                            <img src="https://firebasestorage.googleapis.com/v0/b/elearning-de474.appspot.com/o/images%2Fnathan-dumlao-ewGMqs2tmJI-unsplash.jpg?alt=media&token=b462cb2c-4086-4e7b-941c-f5bb3d75e570" alt="" className="user-exp-image" />
                            <div className="user-exp-info">
                                <p className="user-exp-head">
                                    Affordable Education
                                </p>
                                <p className="user-exp-desc">
                                    Empowering learners of all backgrounds
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </section>
        </main>
    )
}

export default SuccessStories